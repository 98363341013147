import React, { Component } from 'react';
import css from 'CSS/components/ui/splashBackground.css';

export default class SplashBackground extends Component {
    render() {
        return (
            <div className={css.splashBackground + " engiePrimaryBackgroundColour engiePrimaryBackgroundGradient"}/>
        );
    }
}
