// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".setDateDialogContent__container___meVsr {\n    padding: 0 15px 15px 15px;\n    margin: 0 auto;\n    height: 400px;\n    width: 298px;\n}\n\n.setDateDialogContent__dateContainer___u487T {\n    height: 80px;\n}\n\n.setDateDialogContent__container___meVsr input {   \n    outline: 0;  \n    box-shadow: none !important;\n    padding: 0;\n    text-align: center;\n    color: #00aaff;\n    font-weight: 500;\n    margin-bottom: 10px;\n}\n\n.setDateDialogContent__dateIcon___f_Rqz {\n    float: left;\n    color: #fff;\n}\n\n.setDateDialogContent__forceText___P3EFY {\n    font-size: 16px;   \n    float: left;\n    margin-left: 8px;\n    font-weight: 300;\n    color: #fff;\n}\n\n.setDateDialogContent__clockAcceptedText___JLvcF {\n    font-size: 12px;\n}\n\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/dialog/customContent/setDateDialogContent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,2BAA2B;IAC3B,UAAU;IACV,kBAAkB;IAClB,cAAc;IACd,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".container {\n    padding: 0 15px 15px 15px;\n    margin: 0 auto;\n    height: 400px;\n    width: 298px;\n}\n\n.dateContainer {\n    height: 80px;\n}\n\n.container input {   \n    outline: 0;  \n    box-shadow: none !important;\n    padding: 0;\n    text-align: center;\n    color: #00aaff;\n    font-weight: 500;\n    margin-bottom: 10px;\n}\n\n.dateIcon {\n    float: left;\n    color: #fff;\n}\n\n.forceText {\n    font-size: 16px;   \n    float: left;\n    margin-left: 8px;\n    font-weight: 300;\n    color: #fff;\n}\n\n.clockAcceptedText {\n    font-size: 12px;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "setDateDialogContent__container___meVsr",
	"dateContainer": "setDateDialogContent__dateContainer___u487T",
	"dateIcon": "setDateDialogContent__dateIcon___f_Rqz",
	"forceText": "setDateDialogContent__forceText___P3EFY",
	"clockAcceptedText": "setDateDialogContent__clockAcceptedText___JLvcF"
};
export default ___CSS_LOADER_EXPORT___;
