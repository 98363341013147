import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/dialog/dialogHeader.css';

import ScreenName from 'JS/components/ui/screenName';

function DialogHeader(props) {
    return (
       
        <div className="modal-header">
            <ScreenName loading={false} screenNameText={props.dialogTitle} />
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    );
}

DialogHeader.propTypes = {
    dialogTitle: PropTypes.string.isRequired
};

export default DialogHeader;