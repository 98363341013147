// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.panelContainer__panelShadow___nIwCJ {  \n    margin: 15px 3px 17px 3px;\n    border: 1px solid #D0D0D0;\n    -webkit-box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n    -moz-box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n    box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/panelContainer.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;IACzB,yBAAyB;IACzB,2DAA2D;IAC3D,wDAAwD;IACxD,mDAAmD;AACvD","sourcesContent":["\n.panelShadow {  \n    margin: 15px 3px 17px 3px;\n    border: 1px solid #D0D0D0;\n    -webkit-box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n    -moz-box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n    box-shadow: 0px 0px 18px 0px rgba(208,208,208,0.84);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelShadow": "panelContainer__panelShadow___nIwCJ"
};
export default ___CSS_LOADER_EXPORT___;
