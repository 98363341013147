import React from 'react';
import PropTypes from 'prop-types';
import loadingGraphic from '../../../assets/images/container/loadingGraphic.gif';
import css from 'CSS/components/ui/loadingGraphic.css';

export default function LoadingGraphic(props) {
    return <img className="loadingGraphic" style={props.style} src={loadingGraphic} />;
}

LoadingGraphic.propTypes = {
    style: PropTypes.object
};