import * as types from 'JS/actions/actionTypes';
import * as appText from 'JS/language/en/appText';
import meterReadingsApi from 'JS/service/api/meterReadingsApi';
import { getLatestToken } from 'JS/util/authenticationHelper';

const effectReconciler = (effect, action) => {
    switch (action.type) {
        case types.SUBMIT_READING: {
            return meterReadingsApi.submitMeterReading(getLatestToken(), effect.meterId, effect.reading, effect.date, effect.enteredDate);
        }

        default: return Promise.reject(appText.OFFLINE_ACTION_ERROR_TEXT);
    }
};

export default effectReconciler;
