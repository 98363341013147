import React from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/reading/previousReading.css';

function PreviousReading(props) {
        
    return (
        <div className={css.meterReadingPrevious + " engiePrimaryColour"}><span>Previous Reading: </span>{props.previousReading}</div>
    );
}

PreviousReading.propTypes = {
    previousReading: PropTypes.string.isRequired
};

export default PreviousReading;
  