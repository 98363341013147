import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LoadingGraphic from 'JS/components/ui/loadingGraphic';
import css from 'CSS/components/ui/screenName.css';

export default class ScreenName extends Component {
           
    render() {
        
        const loadingStyles = {
            width: '36px',
            marginTop: '-9px'
        };
        
        return (
            <div>
                <div className={css.screenNameText + " engiePrimaryColour"}>
                    {this.props.screenNameText}
                </div>
                <span className="loadingGraphicContainer">{this.props.loading && <LoadingGraphic style={loadingStyles} />}</span>
                <div className={css.screenNameUnderline + " engiePrimaryBackgroundColour"}/>
            </div>
        );
    }
}

ScreenName.propTypes = {
    screenNameText: PropTypes.string.isRequired,
    loading: PropTypes.bool
};

