/* Example of how to make a warning dialog with ok AND cancel buttons

    <ModalDialog open={false}>
        <DialogHeader  dialogTitle={appText.WARNING_DIALOG_TITLE} />
        <MessageDialogContent dialogMessage={appText.READING_INPUT_WARNING_MESSAGE} />
        <OkCancelDialogFooter onOkClick={this.saveReading} />
    </ModalDialog>
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';

import css from 'CSS/components/dialog/modalDialog.css';

class ModalDialog extends Component {
     
    componentDidMount() {
        
        $('#' + this.props.id).on("hide.bs.modal", function () {
            $('.appContainer').removeClass('blurBackground');
            $('footer').removeClass('blurBackground');
        });

        $('#' + this.props.id).on("show.bs.modal", function () {
            $('.appContainer').addClass('blurBackground');
            $('footer').addClass('blurBackground');
        });

        this.showDialog();
    }

    componentDidUpdate() {
        this.showDialog();
    }

    componentWillUnmount() {
        $('.appContainer').removeClass('blurBackground');
        $('#' + this.props.id).modal('hide');
    }

    showDialog() {
        let action = (this.props.open) ? 'show' : 'hide';
        $('#' + this.props.id).modal(action);
    }
      
    render() {
        
       return ReactDOM.createPortal(
       
            <div className={css.modalDialogContainer}>
                <div className="modal fade" id={this.props.id} role="dialog" aria-labelledby="modalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>,
            document.body
        );
    }
}

ModalDialog.propTypes = {
    id: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default ModalDialog;