export const LOGIN = "/login";
export const ROUNDS = "/";
export const ROUND = "/round";
export const READING = "/reading/";
export const ROUND_WITH_METER_ROUND_ID = ROUND + "/:meterRoundId";
export const TAKE_READING = ROUND + "/:meterRoundId" + READING + ":meterId";
export const ROUND_WITH_METER_ROUND_ID_AND_METER_ID = ROUND + "/:meterRoundId/:meterId";



