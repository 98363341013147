import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SiteList from 'JS/components/site/siteList';

export default class Contract extends Component{
    render() {
        return (
            <li>
                <div className="contractHeading">{this.props.contract.name}</div>
                <SiteList contractId={this.props.contract.contractId}/>
            </li>
        );
    }
}

Contract.propTypes = {
    contract: PropTypes.object.isRequired
};