import { get } from './baseApi';

class ContractApi {
    static getContracts(token) {
        return get(token, '/contract/filtered?query=has:siteWithRounds');
    }
    
    static getContract(token, contractId) {
        return get(token, '/contract/'+contractId);
    }
}

export default ContractApi;