import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import {Provider} from 'react-redux';
import 'bootstrap';

import loadPolyfills from 'JS/loadPolyfills';
import Routes from 'JS/routes';
import {registerServiceWorker} from 'JS/util/serviceWorker';
import {loadDownloadedStatusOfMeterRounds} from 'JS/actions/meterRoundActions';
import configureStore from 'JS/store/configureStore';
import {detectDevice} from 'JS/util/device';

import '../../../node_modules/toastr/build/toastr.min.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import 'CSS/app.css';

loadPolyfills().then(() => {
    function onStoreRehydrated() {
        //Once locally persisted data is restored
        store.dispatch(loadDownloadedStatusOfMeterRounds());
    }
    
    const store = configureStore(onStoreRehydrated);

    ReactDOM.render(
        <Provider store={store}>
            <Router>
                <Routes />
            </Router>
        </Provider>,
        document.getElementById('react')
    );

    detectDevice();
    registerServiceWorker();

});
