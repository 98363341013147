import {createStore, compose } from 'redux';
import rootReducer from 'JS/reducers/rootReducer';

import applyBuildSpecificMiddleware from 'JS/store/applyBuildSpecificMiddleware';

import { offline } from '@redux-offline/redux-offline';
import discard from '@redux-offline/redux-offline/lib/defaults/discard';
import retry from '@redux-offline/redux-offline/lib/defaults/retry';
import detectNetwork from '@redux-offline/redux-offline/lib/defaults/detectNetwork';
import persist from '@redux-offline/redux-offline/lib/defaults/persist';

import effectReconciler from 'JS/offline/c3ntinelApiEffectReconciler';

export default function configureStore(rehydraytionCallback, initialState) {
    const offlineConfig = {
        retry,
        discard,
        effect: effectReconciler,
        detectNetwork,
        persist,
        persistCallback: rehydraytionCallback,
        persistOptions: {
            whitelist: ['offline', 'meterRoundProgress', 'meterRoundDetails']
        }
    };
    
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const enhancer = composeEnhancers(
        applyBuildSpecificMiddleware(),
        offline(offlineConfig),
        //Crazy fix from here: https://github.com/zalmoxisus/redux-devtools-extension/issues/365
        (createStore) => (reducer, preloadedState, enhancer) => enhancer(createStore)(reducer, preloadedState)
    );

    return createStore(
        rootReducer,
        initialState,
        enhancer
    );
}
