// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dateDialogOptions__dateRadios___pp6Mz {\n    margin-bottom: 10px;\n}\n.dateDialogOptions__dateRadioContainer___QOUPN {\n    position: relative;  \n    text-align: left;\n}\n\n.custom,\n.clock {\n    background: #00aaff;\n    padding: 7px 7px 7px 40px;\n    border: 1px solid #00aaff;\n    cursor: pointer;\n    color: white;\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: 12px;\n    display: block;\n    margin-bottom: 3px;\n}\n\ninput[type=\"radio\"] {\n    position: absolute;    \n    top: 11px;\n    left: 20px;\n}\n\ninput[type=\"radio\"]:checked + label {\n    background: #025aa5;\n    border: 1px solid #04364e;\n    color: white;\n}\n\n\n\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/dialog/customContent/dateDialogOptions.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;;IAEI,mBAAmB;IACnB,yBAAyB;IACzB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,yCAAyC;IACzC,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;AAChB","sourcesContent":[".dateRadios {\n    margin-bottom: 10px;\n}\n.dateRadioContainer {\n    position: relative;  \n    text-align: left;\n}\n\n:global(.custom),\n:global(.clock) {\n    background: #00aaff;\n    padding: 7px 7px 7px 40px;\n    border: 1px solid #00aaff;\n    cursor: pointer;\n    color: white;\n    font-family: Arial, Helvetica, sans-serif;\n    font-size: 12px;\n    display: block;\n    margin-bottom: 3px;\n}\n\ninput[type=\"radio\"] {\n    position: absolute;    \n    top: 11px;\n    left: 20px;\n}\n\ninput[type=\"radio\"]:checked + label {\n    background: #025aa5;\n    border: 1px solid #04364e;\n    color: white;\n}\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateRadios": "dateDialogOptions__dateRadios___pp6Mz",
	"dateRadioContainer": "dateDialogOptions__dateRadioContainer___QOUPN"
};
export default ___CSS_LOADER_EXPORT___;
