import { post } from 'JS/service/api/baseApi';
import { getDate } from 'JS/util/dateFunctions';
import { ReadingNotProcessableError, DuplicateReadingError } from 'JS/errors/errors';

import * as httpStatusCodes from 'JS/util/httpStatusCodes';

import * as appText from 'JS/language/en/appText';

class MeterReadingsApi {
    static submitMeterReading(token, meterId, reading, date, enteredDate) {
        
        date = getDate(date);
        
        const newReading = {
            meterId,
            manualReading : {
                value: reading,
                time: date.getTime(),
                status: "OK",
                enteredDate: enteredDate
            }
        };
        
        return post(token, '/reading', newReading)
            .catch(e => {
                
                switch(e.status) {
                    case httpStatusCodes.NOT_MODIFIED:
                        return Promise.reject(new DuplicateReadingError(appText.READING_IS_DUPLICATE_ERROR_MESSAGE, e.response));
                    default:
                        return Promise.reject(new ReadingNotProcessableError(appText.READING_NOT_PROCESSED_ERROR_MESSAGE, e.response));
                  }
            });
    }
}

export default MeterReadingsApi;