import * as types from 'JS/actions/actionTypes';
import initialState from './initialState';

export default function meterRoundReducer(state = initialState.meterRounds, action) {
    switch(action.type) {
        case types.GET_METER_ROUNDS_SUCCESS: {
            return [...state.filter(round => round.siteId != action.siteId), ...action.meterRounds];
        }
        
        case types.GET_METER_ROUND_SUCCESS: {
            return [...state.filter(round => round.meterRoundId != action.meterRoundId), action.meterRound];
        }
        
        default:
            return state;
    }
}
