import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PanelContainer from 'JS/components/ui/panelContainer';

import css from 'CSS/components/reading/meterReadingInput.css';

export default class MeterReadingInput extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.updateReadingState = this.updateReadingState.bind(this);
    }
    
    updateReadingState(event) {
        const reading = event.target.value;
        
        const readingValue = Number.parseFloat(reading);
        //TODO: Handle not a number
        
        return this.props.onReadingChanged(readingValue);
    }
    
    render() {
        return (
            <div className={css.meterReadingInput + " engie-input"}>
                <input type="number" id="meterReading" onChange={this.updateReadingState} />
            </div>
        );
    }
}

MeterReadingInput.propTypes = {
    onReadingChanged: PropTypes.func.isRequired
};