import React, { Component } from 'react';

import css from 'CSS/components/ui/footer.css';

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="row row justify-content-center">
                    <div className={css.engieFooter + " engie-bar-small bar footer"}/>
                </div>
            </footer>
        );
    }
}