// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nfooter {\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: absolute;\n    padding: 0 0 15px;\n}\n\n.footer__engieFooter___k9O5h {\n    border-radius: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/footer.css"],"names":[],"mappings":";AACA;IACI,OAAO;IACP,QAAQ;IACR,SAAS;IACT,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\nfooter {\n    left: 0;\n    right: 0;\n    bottom: 0;\n    position: absolute;\n    padding: 0 0 15px;\n}\n\n.engieFooter {\n    border-radius: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"engieFooter": "footer__engieFooter___k9O5h"
};
export default ___CSS_LOADER_EXPORT___;
