import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { requestLogin } from 'JS/actions/apiLoginActions';
import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';

const LoginControl = ({ isLoggedIn, login }) => {
    const link = isLoggedIn ?
        <Redirect to={paths.ROUNDS} /> :
        <button type="button"
                className="engie-btn btn-outline-primary btn-rounded engiePrimaryOutlineButtonColour"
                onClick={login}>{appText.LOG_IN_BUTTON_TEXT}</button>;

    return (
        <div className="row justify-content-center">
            {link}
        </div>
       
    );
};

LoginControl.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    login: PropTypes.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
    isLoggedIn: auth.isLoggedIn
});
 
const mapDispatchToProps = {
    login: () => requestLogin()
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginControl);

