import React from 'react';
import PropTypes from 'prop-types';

import * as appText from 'JS/language/en/appText';

import css from 'CSS/components/meterRound/meterRoundProgressInfo.css';

function MeterRoundProgressInfo(props) {
    
    const totalMetersUnRead = props.totalMetersUnRead;
    const totalMetersInRound = props.totalMetersInRound;
    
    const meterText = (totalMetersUnRead === 1) ? appText.METER_TEXT : appText.METERS_TEXT;
    const completedText = (props.roundComplete) ? appText.COMPLETED_TEXT : appText.PARTIALLY_COMPLETED_TEXT;
    
    let progressText = totalMetersUnRead + " " + meterText + appText.REMAINING_TEXT +
                       totalMetersInRound + appText.WITH_TEXT +
                       props.totalMetersIgnored + appText.IGNORED_TEXT;
    
    if (totalMetersInRound === 0) {
        progressText = "";
    }
      
    return (
        <div>
            <div className={css.partiallyCompletedText + " engiePrimaryColour"}>{completedText}</div>
            <div>{progressText}</div>
        </div>
    );
}

MeterRoundProgressInfo.propTypes = {
    totalMetersInRound: PropTypes.number.isRequired,
    totalMetersUnRead: PropTypes.number.isRequired,
    totalMetersIgnored: PropTypes.number.isRequired,
    roundComplete: PropTypes.bool.isRequired
};

export default MeterRoundProgressInfo;

