
export function isInvalidReading(submittedReading) {
    return (!submittedReading || submittedReading < 0);
}

export function isGreaterThanRollover(submittedReading, meterIsCumulative, meterRolloverValue) {
    return (meterIsCumulative && (submittedReading > meterRolloverValue));
}

export function isPossibleRollover(submittedReading, meterIsCumulative, previousReading) {
    return (meterIsCumulative && (submittedReading < previousReading));
}
