import * as types from 'JS/actions/actionTypes';
import meterRoundApi from 'JS/service/api/meterRoundApi';
import meterReadingsApi from 'JS/service/api/meterReadingsApi';

import MeterRoundProgress, { MeterRoundProgressStatus, SyncStatus } from 'JS/dto/meterRoundProgress';
import { beginAjaxCall, ajaxCallError } from 'JS/actions/ajaxStatusActions';

import * as appText from 'JS/language/en/appText';
import * as dateOptions from 'JS/util/readingDateOptions';

import moment from 'moment';

export function startMeterRoundSuccess(meterRoundId, progress) {
    return {
        type: types.START_METER_ROUND_SUCCESS,
        meterRoundId,
        progress
    };
}

export function startMeterRoundFailed(meterRoundId, progress, error) {
    return {
        type: types.START_METER_ROUND_FAILED,
        meterRoundId,
        progress,
        error
    };
}

export function startMeterRound(roundId, progress) {
    return function(dispatch, getState) {
        let newProgress;
        try {
            newProgress = MeterRoundProgress.startRound(progress);
        } catch (err) {
            return Promise.reject(err);
        }
        
        dispatch(beginAjaxCall());
        
        return Promise.resolve(dispatch(startMeterRoundSuccess(roundId, newProgress)));
    };
}

export function storeMeterRoundProgressDataSuccess(meterRoundId, progress) {
    return {
        type: types.STORE_METER_ROUND_PROGRESS_DATA_SUCCESS,
        meterRoundId,
        progress
    };
}

export function storeMeterRoundProgressData(roundId, details) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return Promise.resolve(dispatch(storeMeterRoundProgressDataSuccess(roundId, details)));
    };
}

export function deleteMeterRoundProgressSuccess(meterRoundId) {
    return {
        type: types.DELETE_METER_ROUND_PROGRESS_SUCCESS,
        meterRoundId
    };
}

export function deleteMeterRoundProgress(meterRoundId, forceDelete) {
    return function(dispatch, getState) {
        const currentProgress = getState().meterRoundProgress[meterRoundId];
        
        if(!forceDelete && currentProgress && currentProgress.readings){
            for(const i in currentProgress.readings){
                if(currentProgress.readings[i].uploadStatus && currentProgress.readings[i].uploadStatus.id != SyncStatus.SUCCESS.id){
                    return Promise.reject(appText.NOT_ALL_READINGS_UPLOADED);
                }
            }
        }
        
        return Promise.resolve(dispatch(deleteMeterRoundProgressSuccess(meterRoundId)));
    };
}

export function ignoreMeterSuccess(meterRoundId, meterId, progress) {
    return {
        type: types.IGNORE_METER_SUCCESS,
        meterRoundId,
        meterId,
        progress
    };
}

export function setReadingDateSuccess(meterRoundId, readingDate, progress) {
    return {
        type: types.SET_READING_DATE_SUCCESS,
        meterRoundId,
        readingDate,
        progress
    };
}

export function ignoreMeter(meterRoundId, meterId){
    return function(dispatch, getState) {
        const currentProgress = getState().meterRoundProgress[meterRoundId];
        
        const newProgress = MeterRoundProgress.ignoreMeter(currentProgress, meterId);
        
        return Promise.resolve(dispatch(ignoreMeterSuccess(meterRoundId, meterId, newProgress)));
    };
}

export function setReadingDate(meterRoundId, readingDate){
    return function(dispatch, getState) {
        const currentProgress = getState().meterRoundProgress[meterRoundId];
        
        const newProgress = MeterRoundProgress.setReadingDate(currentProgress, readingDate);
        
        return Promise.resolve(dispatch(setReadingDateSuccess(meterRoundId, readingDate, newProgress)));
    };
}

export function reinstateMeterSuccess(meterRoundId, meterId, progress) {
    return {
        type: types.REINSTATE_METER_SUCCESS,
        meterRoundId,
        meterId,
        progress
    };
}

export function reinstateMeter(meterRoundId, meterId){
    return function(dispatch, getState) {
        const currentProgress = getState().meterRoundProgress[meterRoundId];
        
        const newProgress = MeterRoundProgress.reinstateMeter(currentProgress, meterId);
        
        return Promise.resolve(dispatch(reinstateMeterSuccess(meterRoundId, meterId, newProgress)));
    };
}

export function submitReadingComplete(meterRoundId, meterId, reading, date, enteredDate) {
    return {
        type: types.SUBMIT_READING_COMPLETE,
        meterRoundId,
        meterId,
        reading,
        date,
        enteredDate
    };
}

export function submitReadingRollback(meterRoundId, meterId, reading, date, enteredDate) {
    return {
        type: types.SUBMIT_READING_FAILED,
        meterRoundId,
        meterId,
        reading,
        date,
        enteredDate
    };
}

export function submitReadingPending(meterRoundId, meterId, reading, date, enteredDate, progress) {
    return {
        type: types.SUBMIT_READING,
        progress: progress,
        meterRoundId: meterRoundId,
        meta: {
            offline: {
                effect: {
                    meterRoundId,
                    meterId,
                    reading,
                    date,
                    enteredDate
                },
                commit: submitReadingComplete(meterRoundId, meterId, reading, date, enteredDate),
                rollback: submitReadingRollback(meterRoundId, meterId, reading, date, enteredDate)
            }
        }
    };
}

export function submitReading(meterRoundId, meterId, reading, dateString){
    return function(dispatch, getState) {
              
        const currentProgress = getState().meterRoundProgress[meterRoundId];
        var date;
        var enteredDate = null;

        if (dateString === dateOptions.CLOCK) {
            
            // User has selected to use current clock time
            date = new Date();
            
        } else {
            
            // User has set a custom date/time to use
            date = new Date(dateString);
            
            enteredDate = moment(date).utc().format("DD/MM/YYYY HH:mm:ss");
        }
        
        const newProgress = MeterRoundProgress.takeReading(currentProgress, meterId, reading, date, enteredDate);
        
        return Promise.resolve(dispatch(submitReadingPending(meterRoundId, meterId, reading, date, enteredDate, newProgress)));
    };
}