import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/meter/meterReadStatus.css';


class MeterReadStatus extends Component {
    
    render() {
      
        return (
            <div>
                <div className={css.meterReadStatusIcon + " " + this.props.uploadStatus}><i className="engie-icons">autorenew</i></div>
                <div  className={css.errorStatusMessage}>{this.props.uploadStatusMessage}</div>
            </div>
        );
    }
}

MeterReadStatus.propTypes = {
    uploadStatus: PropTypes.string.isRequired,
    uploadStatusMessage: PropTypes.string.isRequired
};

export default MeterReadStatus;
