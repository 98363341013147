// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".engie-btn i.engie-icons {\n    padding-left: 0;\n    padding-right: 0;\n    top: 5px;\n    position: relative;\n} \n\n.modal-footer {\n    justify-content: center !important;\n    border-top: none !important;\n} \n\n.dialogFooter__footer___wT_yq {\n    color: #fff;\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/dialog/dialogFooter.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,QAAQ;IACR,kBAAkB;AACtB;;AAEA;IACI,kCAAkC;IAClC,2BAA2B;AAC/B;;AAEA;IACI,WAAW;AACf","sourcesContent":[":global(.engie-btn i.engie-icons) {\n    padding-left: 0;\n    padding-right: 0;\n    top: 5px;\n    position: relative;\n} \n\n:global(.modal-footer) {\n    justify-content: center !important;\n    border-top: none !important;\n} \n\n.footer {\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footer": "dialogFooter__footer___wT_yq"
};
export default ___CSS_LOADER_EXPORT___;
