// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.meterReadStatus__meterReadStatusIcon___W_TEo i {\n    float: left;\n    font-size: 16px;\n    margin-right: 3px;\n    color: #ccc;\n    font-weight: bold;\n    position: relative;\n    top: 2px;\n}\n\n.SUCCESS i {\n    color: #69AF23;\n}\n\n.PENDING i {\n    color: #F07D00;\n}\n\n.FAILED i {\n    color: #E10019;\n}\n\n.meterReadStatus__errorStatusMessage___Jftn7 {\n    color: #E10019;\n    font-size: 13px;\n    width: 60%;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/meter/meterReadStatus.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,eAAe;IACf,iBAAiB;IACjB,WAAW;IACX,iBAAiB;IACjB,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,UAAU;AACd","sourcesContent":["\n.meterReadStatusIcon i {\n    float: left;\n    font-size: 16px;\n    margin-right: 3px;\n    color: #ccc;\n    font-weight: bold;\n    position: relative;\n    top: 2px;\n}\n\n:global(.SUCCESS i) {\n    color: #69AF23;\n}\n\n:global(.PENDING i) {\n    color: #F07D00;\n}\n\n:global(.FAILED i) {\n    color: #E10019;\n}\n\n.errorStatusMessage {\n    color: #E10019;\n    font-size: 13px;\n    width: 60%;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterReadStatusIcon": "meterReadStatus__meterReadStatusIcon___W_TEo",
	"errorStatusMessage": "meterReadStatus__errorStatusMessage___Jftn7"
};
export default ___CSS_LOADER_EXPORT___;
