
export function getMeterDataItem(meters, item, meterId) {
    for (let i = 0, len = meters.length; i < len; i++) {
        if (meters[i].meterId.toString() === meterId) {
            return  meters[i][item];
        }
    }
}

export function getDataFeedUnitType(meterProperties, meterId) {
      
    if (meterProperties !== null) {
        for (let j = 0, ln = meterProperties.length; j < ln; j++) {
            if (meterProperties[j].meterId.toString() === meterId) {

                let unit = meterProperties[j].dataFeedUnit.unitType;

                if (unit !== "" && unit !== null) {
                    return unit;
                }
            }
        }
    }
        
    return null;
}

export function getPreviousReading(latestMeterReadings, meterId) {
    for (let j = 0, ln = latestMeterReadings.length; j < ln; j++) {
        if (latestMeterReadings[j].meterId.toString() === meterId) {

            let readings = latestMeterReadings[j];
                       
            if (readings !== null &&
                readings.reading !== null &&
                readings.reading.value !== null) {
                
                return readings.reading.value.toString();
            }
        }
    }
        
    return null;
}

export function getTotalActiveMetersInRound(meterRoundDetails) {
    
    let totalMetersInRound = 0;
    
    if (!meterRoundDetails || !meterRoundDetails.meters) return totalMetersInRound;
           
    let roundMeters = meterRoundDetails.meters;

    for (let i = 0, ln = roundMeters.length; i < ln; i++) {
        if (!roundMeters[i].archived) {
            totalMetersInRound++;
        }
    }
    
    return totalMetersInRound;
}

export function getTotalIgnoredMetersInRound(metersRead) {
    return getTotalMetersInRound(metersRead, addToIgnoredTotal);
}

export function getTotalReadMetersInRound(metersRead) {
    return getTotalMetersInRound(metersRead, addToReadTotal);
}

function getTotalMetersInRound(metersRead, filter) {
     
    let totalMeters = 0;
    
    if (!metersRead) return totalMeters;
  
    for (let property in metersRead) {
        if (filter(metersRead[property])) {
            totalMeters++;
        }
    }
      
    return totalMeters;
}

function addToReadTotal(meterReading) {
    return (meterReading && (meterReading.reading || meterReading.isIgnored));
}

function addToIgnoredTotal(meterReading) {
    return (meterReading && meterReading.isIgnored);
}

export function getMeterPropertyByMeterId(meterProperties, meterId, propertyName) {
    
     if (meterProperties !== null) {
        for (let j = 0, ln = meterProperties.length; j < ln; j++) {
            if (meterProperties[j].meterId.toString() === meterId) {
                
                let prop = meterProperties[j][propertyName];
                
                if (prop !== null) {
                    return prop;
                }
            }
        }
    }
        
    return null;
}




