import * as types from 'JS/actions/actionTypes';
import initialState from './initialState';

export default function meterRoundDetailsReducer(state = initialState.meterRoundDetails, action) {
    switch(action.type) {
        case types.GET_METER_ROUND_DETAILS_SUCCESS: {
            let meterRounds = {};
            meterRounds[action.meterRoundId] = action.meterRoundDetails;
            
            return Object.assign({}, state, meterRounds);
        }
        
        default:
            return state;
    }
}