import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MainListingPage from 'JS/pages/mainListingPage';

class MainListingContainer extends Component {
          
    render() {
        return (
            <MainListingPage loading={this.props.loading} />
        );
    }
}

MainListingContainer.propTypes = {
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        loading: state.ajaxCallsInProgress > 0
    };
}

export default connect(mapStateToProps)(MainListingContainer);