import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router-dom';

import MeterReadStatus from 'JS/components/meter/meterReadStatus';
import IgnoreMeterToggle from 'JS/components/meter/ignoreMeterToggle';
import MeterRoundProgress, { SyncStatus } from 'JS/dto/meterRoundProgress';

import * as meterRoundProgressActions from 'JS/actions/meterRoundProgressActions';
import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';
import * as dates from 'JS/util/dateFunctions';

import css from 'CSS/components/meter/meterListItem.css';

const NOT_UPLOADED_YET = "";

export class MeterListItem extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.retrySavingReading = this.retrySavingReading.bind(this);
        this.ignoreMeter = this.ignoreMeter.bind(this);
        this.reinstateMeter = this.reinstateMeter.bind(this);
        this.toggleIgnoredStatus = this.toggleIgnoredStatus.bind(this);
        
        // Create a ref object so we know where to scroll to when
        // high lighting the current meter just read
        this.state = {
            itemRef : React.createRef()
        };
    }
    
    componentDidMount() {
        if (this.props.meterId.toString() === this.props.lastReadMeterId) {
            this.scrollToItemRef();
        }
    }
    
    toggleIgnoredStatus(ignore) {
        if(ignore){
            this.ignoreMeter();
        } else {
            this.reinstateMeter();
        }
    }
    
    reinstateMeter(){
        this.props.actions.reinstateMeter(this.props.meterRoundId, this.props.meterId);
    }
    
    ignoreMeter(){
        this.props.actions.ignoreMeter(this.props.meterRoundId, this.props.meterId);
    }

    retrySavingReading() {
        this.props.actions.submitReading(this.props.meterRoundId,
            this.props.meterId,
            this.props.readingValue,
            this.props.readingDate);
    }
       
    scrollToItemRef() {
        window.scrollTo(0, this.state.itemRef.current.offsetTop);
    }
    
    render() {
        
        const linkDisabledClass = (this.props.isArchived || this.props.readingTaken) ? "linkButtonDisabled" : "";
        const readingButtonCss = "engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour " + linkDisabledClass;
        
        const showSuccessHighlight = (this.props.meterId.toString() === this.props.lastReadMeterId &&
                                  this.props.uploadStatus == SyncStatus.SUCCESS.id);
                               
        const showFailureHighlight = (this.props.meterId.toString() === this.props.lastReadMeterId &&
                                  this.props.uploadStatus === SyncStatus.FAILED.id &&
                                  this.props.uploadStatusMessage !== "");
     
        return (
         
            <div className={css.meterListItem} ref={this.state.itemRef}>
                <div className={css.itemHighlightContainer + (showSuccessHighlight ? " " + css.meterListItemHighlight : "") + (showFailureHighlight ? " " + css.meterListItemFailHighlight : "")}>

                    <MeterReadStatus uploadStatus={this.props.uploadStatus} uploadStatusMessage={this.props.uploadStatusMessage} />
                    
                    <div className={css.meterName + (this.props.isArchived ? " " + css.archivedMeter : "")}>{this.props.isArchived && appText.ARCHIVED_METER_PREFIX}{this.props.meterName}</div>
                    <div className={css.readingDate + " engiePrimaryColour"}>{appText.LAST_READING_TEXT}: <span className={css.readingNoBreak}>{this.props.lastReadingText}</span></div>
                  
                    {!this.props.isIgnored && this.props.uploadStatus != SyncStatus.FAILED.id && this.props.uploadStatus != SyncStatus.PENDING.id &&
                    
                        <span><Link to={paths.ROUND + "/" + this.props.meterRoundId + paths.READING + this.props.meterId}
                        className={readingButtonCss}
                        >{this.props.readingTaken ? appText.READING_TAKEN_BUTTON_TEXT : appText.TAKE_READING_BUTTON_TEXT}
                        </Link></span>
                    }

                    {this.props.uploadStatus == SyncStatus.FAILED.id &&
                    
                        <button type="button"
                            className={" engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour "}
                            onClick={this.retrySavingReading}
                            >{appText.RETRY_BUTTON_TEXT}
                        </button>
                    }

                    {this.props.uploadStatus == SyncStatus.PENDING.id &&
                        <div className={css.tryingToSave + " engiePrimaryColour"} >{appText.SAVING_TEXT}</div>
                    }

                    {this.props.canBeIgnored &&
                        <IgnoreMeterToggle isIgnored={this.props.isIgnored} onIgnoreToggle={this.toggleIgnoredStatus}/>
                    }

                    <div className={css.itemHighlightText + " engiePrimaryColour"}>{this.props.highlightText}</div>
                    <div className={css.itemBorderBottom} />

                </div>
            </div>
          
        );
    }
}

MeterListItem.propTypes = {
    meterId: PropTypes.number.isRequired,
    meterRoundId: PropTypes.string.isRequired,
    meterName: PropTypes.string.isRequired,
    lastReadingText: PropTypes.string.isRequired,
    isArchived: PropTypes.bool.isRequired,
    isIgnored: PropTypes.bool.isRequired,
    canBeIgnored: PropTypes.bool.isRequired,
    readingTaken: PropTypes.bool.isRequired,
    uploadStatus: PropTypes.string.isRequired,
    readingValue: PropTypes.number.isRequired,
    readingDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.instanceOf(Date)]).isRequired,
    uploadStatusMessage: PropTypes.string.isRequired,
    highlightText: PropTypes.string.isRequired,
    lastReadMeterId: PropTypes.string,
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(meterRoundProgressActions, dispatch)
    };
}

function mapStateToProps(state, ownProps){
    const meterId = ownProps.meter.meterId;
    const meterName = ownProps.meter.name;
    const meterRoundId = ownProps.meterRoundId;
    const meterRoundDetails = state.meterRoundDetails[meterRoundId];
    const meterRoundProgress = state.meterRoundProgress ? state.meterRoundProgress[meterRoundId] : {};
    const meterProgressData = (state.meterRoundProgress && meterRoundProgress.readings) ? meterRoundProgress.readings[meterId] : {};
    
    let lastestReading = meterRoundDetails.latestMeterReadings.find(reading => reading.meterId === meterId);
    let lastReadingText =(lastestReading && lastestReading.reading) ? new Date(lastestReading.reading.time).toLocaleString() : appText.NO_READINGS_AVAILABLE;
    
    let readingDate = "";
    let readingValue = 0;
    
    if (meterProgressData && meterProgressData.reading && meterProgressData.date) {
        lastReadingText = dates.getDate(meterProgressData.date).toLocaleString();
        readingDate = meterProgressData.date;
        readingValue = meterProgressData.reading;
    }
    
    const readingTaken = !!(meterProgressData && meterProgressData.reading);
    const uploadStatus = (readingTaken) ? meterProgressData.uploadStatus.id : NOT_UPLOADED_YET;
    const uploadStatusMessage = (readingTaken && meterProgressData.uploadStatusMessage) ? meterProgressData.uploadStatusMessage : "";
   
    const isArchived = ownProps.meter.archived;
    const isIgnored = MeterRoundProgress.isIgnored(meterRoundProgress, meterId);
    const canBeIgnored = !isArchived && !readingTaken;
    
    const highlightText = isIgnored ? appText.IGNORED_METER_HIGHLIGHT_TEXT : "";
    
    return {
        meterId,
        meterName,
        lastReadingText,
        isArchived,
        readingTaken,
        uploadStatus,
        uploadStatusMessage,
        isIgnored,
        canBeIgnored,
        highlightText,
        readingDate,
        readingValue
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterListItem);