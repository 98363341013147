import * as types from './actionTypes';

export function requestDebugInformationSuccess(currentState) {
    return {type: types.REQUEST_DEBUG_INFO, currentState};
}

export function requestDebugInformation() {
    return function(dispatch, getState) {
        dispatch(requestDebugInformationSuccess(getState()));
    };
}