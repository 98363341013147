// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meterRoundPage__meterRoundContainer___yPhng {\n    margin: 10px 0 10px 0;;\n}\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/pages/meterRoundPage.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB","sourcesContent":[".meterRoundContainer {\n    margin: 10px 0 10px 0;;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterRoundContainer": "meterRoundPage__meterRoundContainer___yPhng"
};
export default ___CSS_LOADER_EXPORT___;
