export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_DEBUG_INFO = "REQUEST_DEBUG_INFO";
export const BEGIN_AJAX_CALL = "BEGIN_AJAX_CALL";
export const AJAX_CALL_ERROR = "AJAX_CALL_ERROR";
export const GET_CONTRACTS_SUCCESS = "GET_CONTRACTS_ASYNC_SUCCESS";
export const GET_CONTRACT_SUCCESS = "GET_CONTRACT_ASYNC_SUCCESS";
export const GET_SITES_SUCCESS = "GET_SITES_ASYNC_SUCCESS";
export const GET_SITE_SUCCESS = "GET_SITE_ASYNC_SUCCESS";
export const GET_METER_ROUNDS_SUCCESS = "GET_METER_ROUNDS_ASYNC_SUCCESS";
export const GET_METER_ROUND_SUCCESS = "GET_METER_ROUND_ASYNC_SUCCESS";
export const GET_METER_ROUND_DETAILS_SUCCESS = "GET_METER_ROUND_DETAILS_ASYNC_SUCCESS";
export const STORE_METER_ROUND_PROGRESS_DATA_SUCCESS = "STORE_METER_ROUND_PROGRESS_DATA_ASYNC_SUCCESS";
export const LOAD_DOWNLOADED_STATUS_OF_METER_ROUNDS_SUCCESS = "LOAD_DOWNLOADED_STATUS_OF_METER_ROUNDS_ASYNC_SUCCESS";
export const START_METER_ROUND_SUCCESS = "START_METER_ROUND_ASYNC_SUCCESS";
export const START_METER_ROUND_FAILED = "START_METER_ROUND_FAILED";
export const DOWNLOAD_METER_ROUND_DETAILS_SUCCESS = "DOWNLOAD_METER_ROUND_DETAILS_ASYNC_SUCCESS";
export const DOWNLOAD_METER_ROUND_DETAILS_FAILURE = "DOWNLOAD_METER_ROUND_DETAILS_FAILURE";
export const SUBMIT_READING = "SUBMIT_READING";
export const SUBMIT_READING_COMPLETE = "SUBMIT_READING_COMPLETE";
export const SUBMIT_READING_FAILED = "SUBMIT_READING_FAILED";
export const DELETE_METER_ROUND_PROGRESS_SUCCESS = "DELETE_METER_ROUND_PROGRESS_SUCCESS";
export const IGNORE_METER_SUCCESS = "IGNORE_METER_SUCCESS";
export const REINSTATE_METER_SUCCESS = "REINSTATE_METER_SUCCESS";
export const SET_READING_DATE_SUCCESS = "SET_READING_DATE_SUCCESS";