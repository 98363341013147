import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScreenName from 'JS/components/ui/screenName';
import MeterList from 'JS/components/meter/meterList';
import MeterRoundHeader from 'JS/components/meterRound/meterRoundHeader';
import MeterListHeader from 'JS/components/meter/meterListHeader';
import PanelContainer from 'JS/components/ui/panelContainer';
import BackLink from 'JS/components/ui/backLink';

import * as appText from 'JS/language/en/appText';
import * as path from 'JS/util/paths';

import css from 'CSS/pages/meterRoundPage.css';

class MeterRoundPage extends Component {
        
    render() {
        
        const siteName = (this.props.siteName) ? this.props.siteName : appText.LOADING_TEXT;
        const contractName = (this.props.contractName) ? this.props.contractName : appText.LOADING_TEXT;
        const meterRoundName = (this.props.meterRound && this.props.meterRound.name) ? this.props.meterRound.name : appText.LOADING_TEXT;
        const meters = this.props.meterRoundDetails ? this.props.meterRoundDetails.meters : [];
                  
        return (
            <div>
                <BackLink url={path.ROUNDS}
                          linkText={appText.BACK_TO_METER_ROUND_LIST_BUTTON_TEXT} />
                          
                <ScreenName loading={this.props.loading} screenNameText={appText.ACTIVE_METER_ROUND_TEXT} />
                
                <div className={css.meterRoundContainer}>
                
                    <MeterRoundHeader
                        contractName={contractName}
                        siteName={siteName}
                    />
                    
                    <PanelContainer>
                        <MeterListHeader meterRoundName={meterRoundName}/>
                        <MeterList meterRoundId={this.props.meterRoundId} meters={meters} lastReadMeterId={this.props.lastReadMeterId} />
                    </PanelContainer>
                </div>
            </div>
        );
    }
}

MeterRoundPage.propTypes = {
    loading: PropTypes.bool.isRequired,
    meterRoundId: PropTypes.string.isRequired,
    siteName: PropTypes.string,
    contractName: PropTypes.string,
    meterRound: PropTypes.object,
    lastReadMeterId: PropTypes.string,
    meterRoundDetails: PropTypes.object
};

export default MeterRoundPage;


