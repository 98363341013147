import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withRouter } from 'react-router-dom';

import PanelContainer from 'JS/components/ui/panelContainer';
import MessageDialogContent from 'JS/components/dialog/messageDialogContent';
import ModalDialog from 'JS/components/dialog/modalDialog';
import DialogHeader from 'JS/components/dialog/dialogHeader';
import OkDialogFooter from 'JS/components/dialog/okDialogFooter';
import OkCancelDialogFooter from 'JS/components/dialog/okCancelDialogFooter';
import MeterReadingInput from 'JS/components/reading/meterReadingInput';

import * as meterRoundProgressActions from 'JS/actions/meterRoundProgressActions';

import css from 'CSS/components/reading/meterReadingPanel.css';

import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';
import * as validator from 'JS/util/validator';

export class MeterReadingPanel extends Component {
       
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            reading: null,
            errorDialogOpen: false,
            warningDialogOpen: false,
            dialogMessage: ""
        };
        
        this.updateReadingState = this.updateReadingState.bind(this);
        this.saveReading = this.saveReading.bind(this);
        this.submitReading = this.submitReading.bind(this);
    }
    
    updateReadingState(reading) {
        
        return this.setState({
            reading,
            errorDialogOpen: false,
            warningDialogOpen: false,
            dialogMessage: ""
        });
    }
 
    saveReading(event) {
        
        if (this.readingIsInvalid()) {
            return;
        }
            
        this.submitReading(event);
    }
  
    submitReading(event) {
                   
        this.props.actions.submitReading(this.props.meterRoundId,
                                         this.props.meterId,
                                         this.state.reading,
                                         this.props.readingDate)
        .then(() => this.props.history.push(paths.ROUND + "/" + this.props.meterRoundId + "/" + this.props.meterId))
        .catch (exception => {
            //TODO: Handle properly
            console.log(exception); // eslint-disable-line no-console
        });
    }
    
    setDialogState(errorDialogOpen, warningDialogOpen, dialogMessage) {
                   
        this.setState(state => ({
            errorDialogOpen,
            warningDialogOpen,
            dialogMessage
        }));
    }
    
    readingIsInvalid() {
               
        const submittedReading = this.state.reading;
        const previousReading = parseInt(this.props.previousReading, 10) || 0;
        const meterIsCumulative = this.props.meterIsCumulative;
          
        if (validator.isInvalidReading(submittedReading)){
           
            // Show invalid reading error dialog
            this.setDialogState(true, false, appText.READING_NOT_VALID_INPUT_ERROR_MESSAGE);
            return true;
        }
        
        if (validator.isGreaterThanRollover(submittedReading,
                                            meterIsCumulative,
                                            this.props.meterRolloverValue)) {
            
            // Show reading is greater than rollover error dialog
            this.setDialogState(true, false, appText.READING_GREATER_THAN_ROLLOVER_INPUT_ERROR_MESSAGE);
            return true;
        }
       
        if (validator.isPossibleRollover(submittedReading,
                                         meterIsCumulative,
                                         previousReading)) {
            
            // Show is reading a rollover warning dialog
            this.setDialogState(false, true, appText.READING_IS_ROLLOVER_WARNING_MESSAGE);
            return true;
        }
        
        return false;
    }
      
    render() {
        
        const saveButtonClasses = " engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour";
                 
        return (
            <div>
                <ModalDialog id ={"invalidReadingDialog"} open={this.state.errorDialogOpen}>
                    <DialogHeader dialogTitle={appText.ERROR_DIALOG_TITLE} />
                    <MessageDialogContent dialogMessage={this.state.dialogMessage} />
                    <OkDialogFooter />
                </ModalDialog>
                
                <ModalDialog id ={"warningReadingDialog"} open={this.state.warningDialogOpen}>
                    <DialogHeader dialogTitle={appText.WARNING_DIALOG_TITLE} />
                    <MessageDialogContent dialogMessage={this.state.dialogMessage} />
                    <OkCancelDialogFooter onOkClick={this.submitReading} />
                </ModalDialog>
                             
                <PanelContainer>
                    <div className={css.readingPanelContainer}>
                        <div className={css.newReadingHeading + " engiePrimaryColour"}>{appText.NEW_READING_TEXT}</div>
                        <div className={css.newReadingContainer}>
                            <div className={css.newReadingIcon} />
                            <div className={css.newReading}>
                                <div className={css.newReadingEnterText + " engiePrimaryColour"}>{appText.ENTER_METER_READ_TEXT}</div>
                                <MeterReadingInput
                                    onReadingChanged={this.updateReadingState}
                                />
                                <button type="button"
                                        onClick={this.saveReading}
                                        className={css.saveReadingButton + saveButtonClasses} >

                                    {appText.SAVE_READING_BUTTON_TEXT}
                                </button>
                            </div>
                        </div>
                    </div>
                </PanelContainer>
            </div>
        );
    }
}

MeterReadingPanel.propTypes = {
    meterRoundId: PropTypes.string.isRequired,
    meterId: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    readingDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    meterRolloverValue: PropTypes.number.isRequired,
    meterIsCumulative: PropTypes.bool.isRequired,
    previousReading: PropTypes.string.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(meterRoundProgressActions, dispatch)
    };
}

export default withRouter(connect(null, mapDispatchToProps)(MeterReadingPanel));
