import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { Redirect } from 'react-router-dom';
import toastr from 'toastr';

import MeterRoundPage from 'JS/pages/meterRoundPage';

import * as meterRoundActions from 'JS/actions/meterRoundActions';
import * as siteActions from 'JS/actions/siteActions';
import * as contractActions from 'JS/actions/contractActions';

import * as appText from 'JS/language/en/appText';
import * as path from 'JS/util/paths';


class MeterRoundContainer extends Component {
    
    componentDidMount() {
        // If already in redux store then do not load again
        if(this.props.meterRoundDetails == null) {
            this.props.actions.getMeterRoundDetails(this.props.meterRoundId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_METER_ROUND_DETAILS_TEXT);
            });
        }
        
        if(this.props.meterRound == null) {
            this.props.actions.getMeterRound(this.props.meterRoundId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_METER_ROUND_TEXT);
            });
        }
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.meterRoundProgressLoaded && nextProps.meterRoundProgress == null){
            toastr.error(appText.METER_ROUND_NEEDS_DOWNLOADING);
        }
        
        if(this.props.site == null && nextProps.meterRound != null) {
            this.props.actions.getSite(nextProps.meterRound.siteId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_SITE_TEXT);
            });
        }
        
        if(this.props.contract == null && nextProps.site != null) {
            this.props.actions.getContract(nextProps.site.contractId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_CONTRACT_TEXT);
            });
        }
    }
    
    render() {
        
        if(this.props.meterRoundProgressLoaded && this.props.meterRoundProgress == null) {
            return <Redirect to={path.ROUNDS}/>;
        }
            
        return (
                          
            <MeterRoundPage loading={this.props.loading}
                            meterRound={this.props.meterRound}
                            contractName={(this.props.contract && this.props.contract.name) ? this.props.contract.name : appText.LOADING_TEXT}
                            siteName={(this.props.site && this.props.site.name) ? this.props.site.name : appText.LOADING_TEXT}
                            meterRoundName={this.props.meterRound ? this.props.meterRound.name : appText.LOADING_TEXT}
                            meterRoundId={this.props.meterRoundId}
                            lastReadMeterId={this.props.lastReadMeterId}
                            meterRoundDetails={this.props.meterRoundDetails}
                            meters={this.props.meterRoundDetails ? this.props.meterRoundDetails.meters : []} />
           
        );
    }
}

MeterRoundContainer.propTypes = {
    loading: PropTypes.bool.isRequired,
    meterRoundId: PropTypes.string.isRequired,
    site: PropTypes.object,
    contract: PropTypes.object,
    meterRound: PropTypes.object,
    meterRoundDetails: PropTypes.object,
    meterRoundProgress: PropTypes.object,
    meterRoundProgressLoaded: PropTypes.bool.isRequired,
    lastReadMeterId: PropTypes.string,
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(Object.assign({}, meterRoundActions, siteActions, contractActions), dispatch)
    };
}

function mapStateToProps(state, ownProps) {
    const meterRoundId = ownProps.match.params.meterRoundId;
    const meterRound = state.meterRounds.find(round => round.meterRoundId == meterRoundId);
    const site = meterRound ? state.sites.find(site => site.siteId == meterRound.siteId): null;
    const contract = site ? state.contracts.find(contract => contract.contractId == site.contractId): null;
    const meterRoundDetails = state.meterRoundDetails[meterRoundId];
    const meterRoundProgressLoaded = state.meterRoundProgress != null;
    const meterRoundProgress = meterRoundProgressLoaded ? state.meterRoundProgress[meterRoundId] : null;
   
    let lastReadId = ownProps.match.params.meterId;
    const lastReadMeterId = (typeof lastReadId !== 'undefined') ? lastReadId : "";
    
    return {
        loading: state.ajaxCallsInProgress > 0,
        meterRoundId,
        site,
        contract,
        meterRound,
        meterRoundDetails,
        meterRoundProgress,
        meterRoundProgressLoaded,
        lastReadMeterId
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterRoundContainer);


