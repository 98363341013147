import React, { Component } from 'react';
import PropTypes from 'prop-types';

import DateDialogOptions from 'JS/components/dialog/customContent/dateDialogOptions';
import DateDialogPicker from 'JS/components/dialog/customContent/dateDialogPicker';

import css from 'CSS/components/dialog/customContent/setDateDialogContent.css';
import * as appText from 'JS/language/en/appText';
import * as dateOptions from 'JS/util/readingDateOptions';


class SetDateDialogContent extends Component {
    
    constructor(props, context) {
        
        super(props, context);
       
        this.state = {
            selectedOption: null
        };
        
        this.updateDateState = this.updateDateState.bind(this);
        this.updateDateOptionState = this.updateDateOptionState.bind(this);
    }
    
    updateDateOptionState(selectedOption) {
              
        this.setState({
            selectedOption
        });
        
        return this.props.updateDateOptionState(selectedOption);
    }
    
    updateDateState(readingDate) {
        
        this.setState({
            readingDate
        });
        
        return this.props.updateDateState(readingDate);
    }
            
    render() {
               
        const selectedOption = this.state.selectedOption;
        const defaultDateTime = new Date(this.props.defaultReadingDateTime);
        const customDateDisabled = (selectedOption == null || selectedOption !== dateOptions.CUSTOM);
                      
        return (
            <div className={css.container}>
                <DateDialogOptions meterRoundId={this.props.meterRoundId}
                                   updateDateOptionState={this.updateDateOptionState} />
                                   
                <DateDialogPicker updateDateState={this.updateDateState}
                                  defaultDateTime={defaultDateTime}
                                  disabled={customDateDisabled} />
            </div>
        );
    }
}

SetDateDialogContent.propTypes = {
    defaultReadingDateTime: PropTypes.number.isRequired,
    updateDateState: PropTypes.func.isRequired,
    updateDateOptionState: PropTypes.func.isRequired,
    meterRoundId: PropTypes.number.isRequired
};

export default SetDateDialogContent;


  