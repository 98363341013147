import React, { Component } from 'react';

import LoginPage from 'JS/pages/loginPage';

export default class LoginContainer extends Component {
    render() {
        return (
           <LoginPage />
        );
    }
}