// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.welcomeText__c3ntinel___MrQS_ {\n    padding-top: 5px;\n    font-weight: 900;\n    font-size: 36px;\n    color: #fff;\n}\n\n.welcomeText__c3ntinel___MrQS_ sup {\n    top: 15px;\n}\n\n.welcomeText__appName___q_smT {\n    font-weight: 500;\n    font-size: 14px;\n    margin-bottom: 15px;\n    color: #fff;\n}\n\n@media screen and (orientation: landscape) {\n\n    .welcomeText__engie-font-body-important___Gq_Wf {\n        font-size: 12px;\n    }\n\n    html.tablet .welcomeText__c3ntinel___MrQS_ {\n        font-size: 36px;\n    }\n\n    html.tablet .welcomeText__appName___q_smT {\n        padding-top: 20px;\n    }\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/welcomeText.css"],"names":[],"mappings":";AACA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,eAAe;IACf,WAAW;AACf;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,mBAAmB;IACnB,WAAW;AACf;;AAEA;;IAEI;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,iBAAiB;IACrB;AACJ","sourcesContent":["\n.c3ntinel {\n    padding-top: 5px;\n    font-weight: 900;\n    font-size: 36px;\n    color: #fff;\n}\n\n.c3ntinel sup {\n    top: 15px;\n}\n\n.appName {\n    font-weight: 500;\n    font-size: 14px;\n    margin-bottom: 15px;\n    color: #fff;\n}\n\n@media screen and (orientation: landscape) {\n\n    .engie-font-body-important {\n        font-size: 12px;\n    }\n\n    html:global(.tablet) .c3ntinel {\n        font-size: 36px;\n    }\n\n    html:global(.tablet) .appName {\n        padding-top: 20px;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"c3ntinel": "welcomeText__c3ntinel___MrQS_",
	"appName": "welcomeText__appName___q_smT",
	"engie-font-body-important": "welcomeText__engie-font-body-important___Gq_Wf"
};
export default ___CSS_LOADER_EXPORT___;
