// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.meterRoundProgressInfo__partiallyCompletedText___MB8by {\n    font-size: 13px;\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/meterRound/meterRoundProgressInfo.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB","sourcesContent":["\n.partiallyCompletedText {\n    font-size: 13px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"partiallyCompletedText": "meterRoundProgressInfo__partiallyCompletedText___MB8by"
};
export default ___CSS_LOADER_EXPORT___;
