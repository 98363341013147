import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import MeterListItem from 'JS/components/meter/meterListItem'; // eslint-disable-line import/no-named-as-default

import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';

import css from 'CSS/components/meter/meterList.css';


class MeterList extends Component {
    
    render() {
        const meters = this.props.meters.map(meter =>
            <MeterListItem key={meter.meterId} meter={meter} meterRoundId={this.props.meterRoundId} lastReadMeterId={this.props.lastReadMeterId} />
        );
         
        return (
            
            <div className={css.meterList}>
                {meters}
                <div className={css.navContainer}>
                    <Link to={paths.ROUNDS}
                        className={css.meterListButton + " engie-btn btn-secondary btn-rounded engieSecondaryBackgroundColour engieSecondaryHoverBgColour"}
                        >{appText.BACK_TO_METER_ROUND_LIST_BUTTON_TEXT}
                    </Link>
                </div>
            </div>
        );
    }
}

MeterList.propTypes = {
    meters: PropTypes.array.isRequired,
    lastReadMeterId: PropTypes.string,
    meterRoundId: PropTypes.string.isRequired
};

export default MeterList;
