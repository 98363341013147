import React, {Component} from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';

import { withRouter, Redirect, Route } from 'react-router-dom';

import * as paths from 'JS/util/paths';

class AuthenticatedRoute extends Component {
    
    constructor(props, context) {
        super(props, context);
        
        document.getElementsByTagName('html')[0].classList.add('loggedIn');
    }
           
    render() {
        return this.props.isLoggedIn
        ? <Route path={this.props.path} children={this.props.children}/>
        : <Redirect to={paths.LOGIN}/>;
    }
}

AuthenticatedRoute.propTypes = {
    isLoggedIn: PropTypes.bool.isRequired,
    location: PropTypes.object,
    path: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

function mapStateToProps(state, ownProps){
    return {
        isLoggedIn: state.auth.isLoggedIn,
        location: ownProps.location,
        path: ownProps.path,
        children: ownProps.children
    };
}

export default withRouter(connect(mapStateToProps)(AuthenticatedRoute));