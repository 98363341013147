import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ContractList from 'JS/components/contract/contractList'; // eslint-disable-line import/no-named-as-default
import ScreenName from 'JS/components/ui/screenName';

import * as appText from 'JS/language/en/appText';


class MainListingPage extends Component {
          
    render() {
        return (
                    
            <div>
                <ScreenName loading={this.props.loading} screenNameText={appText.SELECT_METER_ROUND_TEXT} />
                <ContractList />
            </div>
        );
    }
}

MainListingPage.propTypes = {
    loading: PropTypes.bool.isRequired
};

export default MainListingPage;