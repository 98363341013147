import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import { withRouter, Link } from 'react-router-dom';

import css from 'CSS/components/meterRound/meterRound.css';
import * as meterRoundProgressActions from 'JS/actions/meterRoundProgressActions';

import LoadingGraphic from 'JS/components/ui/loadingGraphic';

import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';
import * as dateOptions from 'JS/util/readingDateOptions';

import MeterRoundProgress from 'JS/dto/meterRoundProgress';
import ModalDialog from 'JS/components/dialog/modalDialog';
import DialogHeader from 'JS/components/dialog/dialogHeader';
import OkCancelDialogFooter from 'JS/components/dialog/okCancelDialogFooter';
import SetDateDialogContent from 'JS/components/dialog/customContent/setDateDialogContent';

import toastr from 'toastr';


export class GoToRound extends Component {
    
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            readingDate: this.props.nextReadingDate,
            loadingDetails: false,
            dialogOpen: false
        };
        
        this.openDateDialog = this.openDateDialog.bind(this);
        this.startRound = this.startRound.bind(this);
        this.updateDateState = this.updateDateState.bind(this);
    }
    
    openDateDialog() {
        
        this.setState({
            dialogOpen: true
        });
    }
   
    startRound() {
        if(this.state.readingDate == null) {
            
            toastr.error(appText.INVALID_DATE_ENTERED);
            
            return;
        }

        this.setState({
            dialogOpen: false
        });
                       
        this.props.actions.startMeterRound(this.props.meterRoundId, this.props.meterRoundProgress)
            .then(() => this.setReadingDate())
            .then(() => this.props.history.push(this.props.meterRoundLink))
            .catch (exception => {
                toastr.error(exception);
                this.setState({
                    loadingDetails: false
                });
            });
    }
    
    updateDateState(readingDate) {
        
        this.setState({
            readingDate
        });
    }
    
    updateDateOptionState(selectedOption) {
        
        if (selectedOption === dateOptions.CLOCK) {
            this.updateDateState(dateOptions.CLOCK);
        }
    }
      
    setReadingDate() {
        this.props.actions.setReadingDate(this.props.meterRoundId, this.state.readingDate)
            .catch (exception => {
                toastr.error(exception);
            });
    }
  
    render() {

        return (
            <span>
                <span>
                    {this.props.isStarted ?
                        <Link to={this.props.meterRoundLink}
                            className={css.downloadRoundButton + " engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour"}
                            >{appText.CONTINUE_BUTTON_TEXT}
                        </Link>
                        :
                        <button type="button"
                            className={css.downloadRoundButton + " engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour"}
                            onClick={this.openDateDialog}
                            disabled={this.state.loadingDetails ? "disabled" : ""}
                            >{appText.START_BUTTON_TEXT}
                        </button>
                    }
                    {this.state.loadingDetails && <LoadingGraphic/>}
                </span>

                <ModalDialog id={"datesDialog" + this.props.meterRoundId} open={this.state.dialogOpen}>
                
                    <DialogHeader  dialogTitle={appText.SET_DATE_TEXT} />
                    
                    <SetDateDialogContent updateDateState={this.updateDateState}
                                          updateDateOptionState={this.updateDateOptionState}
                                          meterRoundId ={this.props.meterRoundId}
                                          defaultReadingDateTime={this.props.nextReadingDate} />
                                          
                    <OkCancelDialogFooter onOkClick={this.startRound} />
                            
                </ModalDialog>
            </span>
        );
    }
}

GoToRound.propTypes = {
    meterRoundId: PropTypes.number.isRequired,
    meterRoundLink: PropTypes.string.isRequired,
    nextReadingDate: PropTypes.number.isRequired,
    meterRoundProgress: PropTypes.object.isRequired,
    isStarted: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(meterRoundProgressActions, dispatch)
    };
}

function mapStateToProps(state, ownProps) {
        
    const meterRoundId = ownProps.meterRoundId;
    
    const meterRoundProgress = state.meterRoundProgress ? state.meterRoundProgress[meterRoundId] : {};
           
    return {
        meterRoundProgress,
        meterRoundId,
        meterRoundLink: paths.ROUND + "/" + meterRoundId,
        isStarted : MeterRoundProgress.isStarted(meterRoundProgress),
        history: ownProps.history
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GoToRound));