import * as types from 'JS/actions/actionTypes';
import initialState from 'JS/reducers/initialState';

function actionTypeIsAsynchronous(type){
    return type == types.BEGIN_AJAX_CALL
        || type.substring(type.length - 6) == "_ASYNC";
}

function actionTypeEndsInSuccess(type) {
    return type.substring(type.length - 14) == "_ASYNC_SUCCESS";
}

export default function ajaxStatusReducer(state = initialState.ajaxCallsInProgress, action) {
    if(actionTypeIsAsynchronous(action.type)){
        return state + 1;
    } else if(actionTypeEndsInSuccess(action.type)
            || action.type == types.AJAX_CALL_ERROR) {
        
        if (state <= 0) {
            // eslint-disable-next-line no-console
            console.log("WARNING: An action of type " + action.type + " would have set state to -1");
            return state;
        } else {
            return state - 1;
        }
    }
  
    return state;
}