// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\nheader {\n    width: 100%;\n    height: 40px;\n    font-size: 18px;\n    font-weight: 500;   \n    position: fixed; \n    z-index: 100;\n}\n\n.header__headerText___u57AJ {\n    color: #fff;\n    padding: 7px 0 6px 10px;\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/header.css"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,YAAY;IACZ,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,uBAAuB;AAC3B","sourcesContent":["\nheader {\n    width: 100%;\n    height: 40px;\n    font-size: 18px;\n    font-weight: 500;   \n    position: fixed; \n    z-index: 100;\n}\n\n.headerText {\n    color: #fff;\n    padding: 7px 0 6px 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerText": "header__headerText___u57AJ"
};
export default ___CSS_LOADER_EXPORT___;
