import React from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/meter/meterListHeader.css';

function MeterListHeader(props) {
    return (

        <div className={css.meterListHeader}>
            <div className={css.meterRoundName + " engiePrimaryColour"}>{props.meterRoundName}</div>
            <div className={css.itemBorderBottom} />
        </div>
    );
}

MeterListHeader.propTypes = {
    meterRoundName: PropTypes.string.isRequired
};

export default MeterListHeader;
