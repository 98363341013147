import * as types from 'JS/actions/actionTypes';
import initialState from 'JS/reducers/initialState';
import MeterRoundProgress, { SyncStatus } from 'JS/dto/meterRoundProgress';

export default function meterRoundProgressReducer(state = initialState.meterRoundProgress, action) {
    switch(action.type) {
        case types.SET_READING_DATE_SUCCESS:
        case types.STORE_METER_ROUND_PROGRESS_DATA_SUCCESS:
        case types.START_METER_ROUND_SUCCESS:
        case types.SUBMIT_READING:
        case types.IGNORE_METER_SUCCESS:
        case types.REINSTATE_METER_SUCCESS: {
            let meterRoundProgress = {};
            meterRoundProgress[action.meterRoundId] = action.progress;
            
            return Object.assign({}, state, meterRoundProgress);
        }
        
        case types.DOWNLOAD_METER_ROUND_DETAILS_SUCCESS: {
            let meterRoundProgressStore = {};
            
            meterRoundProgressStore[action.meterRoundId] = Object.assign({}, action.meterRoundProgress);
            
            return Object.assign({}, state, meterRoundProgressStore);
        }
        
        case types.SUBMIT_READING_COMPLETE: {
            let meterRoundProgress = {};
            let currentProgress = Object.assign({}, state[action.meterRoundId]);
            
            const newProgress = MeterRoundProgress.changeReadingUploadStatus(currentProgress,
                                                                             action.meterId,
                                                                             SyncStatus.SUCCESS,
                                                                             "");
            
            meterRoundProgress[action.meterRoundId] = newProgress;
            
            return Object.assign({}, state, meterRoundProgress);
        }
        
        case types.SUBMIT_READING_FAILED: {
            let meterRoundProgress = {};
            let currentProgress = Object.assign({}, state[action.meterRoundId]);
            
            let errorMessage = "";
            
            if (action.payload && action.payload.message) {
                errorMessage = action.payload.message;
            }
            
            const newProgress = MeterRoundProgress.changeReadingUploadStatus(currentProgress,
                                                                             action.meterId,
                                                                             SyncStatus.FAILED,
                                                                             errorMessage);
            
            meterRoundProgress[action.meterRoundId] = newProgress;
            
            return Object.assign({}, state, meterRoundProgress);
        }
        
        case types.DELETE_METER_ROUND_PROGRESS_SUCCESS: {
            if(state[action.meterRoundId]) {
                let meterRoundProgressData = Object.assign({}, state);

                delete meterRoundProgressData[action.meterRoundId];

                return meterRoundProgressData;
            } else return state;
        }
        
        default:
            return state;
    }
}
