// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.meterReadingHeader__meterReadingHeader___fsVNX span {\n    font-weight: bold;\n}\n\n.meterReadingHeader__meterReadingHeader___fsVNX {\n    font-size: 14px;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html.phone .meterReadingHeader__meterReadingHeader___fsVNX {\n        font-size: 12px;\n    }\n}\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/reading/meterReadingHeader.css"],"names":[],"mappings":";AACA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;;IAEI;QACI,eAAe;IACnB;AACJ","sourcesContent":["\n.meterReadingHeader span {\n    font-weight: bold;\n}\n\n.meterReadingHeader {\n    font-size: 14px;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html:global(.phone) .meterReadingHeader {\n        font-size: 12px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterReadingHeader": "meterReadingHeader__meterReadingHeader___fsVNX"
};
export default ___CSS_LOADER_EXPORT___;
