import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as dateOptions from 'JS/util/readingDateOptions';
import * as appText from 'JS/language/en/appText';
import css from 'CSS/components/dialog/customContent/dateDialogOptions.css';

import RadioInput from 'JS/components/ui/radioInput';

export default class DateDialogOptions extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {
            selectedOption: dateOptions.CUSTOM
        };

        this.props.updateDateOptionState(dateOptions.CUSTOM);
        this.updateDateOptionState = this.updateDateOptionState.bind(this);
    }

    updateDateOptionState(event) {

        const value = event.target.value;
        this.setState({ selectedOption: value });

        return this.props.updateDateOptionState(value);
    }

    render() {

        const customChecked = (this.state.selectedOption == dateOptions.CUSTOM);
        const clockChecked = (this.state.selectedOption == dateOptions.CLOCK);
        const meterRoundId = this.props.meterRoundId;

        return (
            <div className={css.dateRadios}>
    
                <div className={css.dateRadioContainer}>

                    <RadioInput updateOptionState={this.updateDateOptionState}
                                checked={customChecked}
                                name={"date" + meterRoundId}
                                value={dateOptions.CUSTOM}
                                id={dateOptions.CUSTOM + meterRoundId}
                                className={dateOptions.CUSTOM}
                                label={appText.SET_CURRENT_CLOCK_TIME} />
                </div>
                <div className={css.dateRadioContainer}>

                    <RadioInput updateOptionState={this.updateDateOptionState}
                                checked={clockChecked}
                                name={"date" + meterRoundId}
                                value={dateOptions.CLOCK}
                                id={dateOptions.CLOCK + meterRoundId}
                                className={dateOptions.CLOCK}
                                label={appText.USE_CURRENT_TIME} />

                </div>

            </div>
        );
    }
}

DateDialogOptions.propTypes = {
     meterRoundId: PropTypes.number.isRequired,
     updateDateOptionState: PropTypes.func.isRequired
};