import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import css from 'CSS/components/ui/backLink.css';


function BackLink(props) {
    return (
            
        <div className={css.backLinkContainer}>
            <div className={css.backLinkIcon}>
                <i className="engie-icons">keyboard_arrow_left</i>
            </div>
            <Link to={props.url}
                className={css.backLink + " engiePrimaryColour engiePrimaryHoverColour"}
                >{props.linkText}
            </Link>
        </div>
    );
}

BackLink.propTypes = {
    url: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired
};

export default BackLink;



  