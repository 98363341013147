import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {bindActionCreators} from 'redux';
import { Redirect } from 'react-router-dom';

import MeterReadingPage from 'JS/pages/meterReadingPage';
import MeterRoundProgress from 'JS/dto/meterRoundProgress';

import * as meterRoundActions from 'JS/actions/meterRoundActions';
import * as dataFunctions from 'JS/util/dataFunctions';
import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';

import toastr from 'toastr';

class MeterReadingContainer extends Component {
    
    componentDidMount() {
        
        // If already in redux store then do not load again
        if (this.props.meterRoundDetails == null) {
            this.props.actions.getMeterRoundDetails(this.props.meterRoundId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_METER_ROUND_DETAILS_TEXT);
            });
        }
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        
        if (nextProps.meterRoundProgressLoaded && nextProps.meterRoundProgress == null){
            toastr.error(appText.METER_ROUND_NEEDS_DOWNLOADING_BEFORE_READING);
        }
    }
           
    render() {
        
        // If user knows the url and tries to go there directly, but have not yet downloaded
        // the round data, then redirect them to the rounds list page
        if (this.props.meterRoundProgressLoaded && this.props.meterRoundProgress == null) {
            return <Redirect to={paths.ROUNDS}/>;
        }
        
        let loadingText = appText.LOADING_TEXT;
                  
        let props = {
            meterId: this.props.meterId,
            meterRoundId: this.props.meterRoundId,
            loading: this.props.loading,
            meterName: this.props.meterName,
            meterRoundDetails: this.props.meterRoundDetails,
            meterUtilityType: this.props.meterUtilityType ? this.props.meterUtilityType : loadingText,
            meterSerialNo: this.props.meterSerialNo ? this.props.meterSerialNo : loadingText,
            meterDataFeedUnit: this.props.meterDataFeedUnit ? this.props.meterDataFeedUnit : loadingText,
            previousReading: this.props.previousReading ? this.props.previousReading : loadingText,
            meterIsArchived: this.props.meterIsArchived,
            meterIsIgnored: this.props.meterIsIgnored,
            meterIsCumulative: this.props.meterIsCumulative,
            meterRolloverValue: this.props.meterRolloverValue,
            readingDate: this.props.readingDate
        };
                
        return (
            <MeterReadingPage {...props} />
        );
    }
}

MeterReadingContainer.propTypes = {
    loading: PropTypes.bool.isRequired,
    meterId: PropTypes.string.isRequired,
    meterRoundId: PropTypes.string.isRequired,
    meterName: PropTypes.string.isRequired,
    actions: PropTypes.object.isRequired,
    meterUtilityType: PropTypes.string.isRequired,
    meterSerialNo: PropTypes.string.isRequired,
    meterDataFeedUnit: PropTypes.string.isRequired,
    previousReading: PropTypes.string.isRequired,
    meterIsArchived: PropTypes.bool.isRequired,
    meterIsIgnored: PropTypes.bool.isRequired,
    meterRoundDetails: PropTypes.object,
    meterRoundProgress: PropTypes.object,
    readingDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    meterRoundProgressLoaded: PropTypes.bool.isRequired,
    meterIsCumulative: PropTypes.bool.isRequired,
    meterRolloverValue: PropTypes.number.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(meterRoundActions, dispatch)
    };
}

function mapStateToProps(state, ownProps) {

    const meterRoundId = ownProps.match.params.meterRoundId;
    const meterRoundDetails = state.meterRoundDetails[meterRoundId];
    const meterId = ownProps.match.params.meterId;
    const meterRoundProgressLoaded = state.meterRoundProgress != null;
    const meterRoundProgress = meterRoundProgressLoaded ? state.meterRoundProgress[meterRoundId] : null;
    const meterIsIgnored = MeterRoundProgress.isIgnored(meterRoundProgress, meterId);
    const readingDate = (meterRoundProgress != null && meterRoundProgress.readingDate != null) ? meterRoundProgress.readingDate : "";
    
    let meterName = "";
    let meterUtilityType = "";
    let meterSerialNo = appText.NO_DATA;
    let previousReading = appText.NO_DATA;
    let meterDataFeedUnit = appText.NO_DATA;
    let meterIsArchived = false;
    let meterIsCumulative = false;
    let meterRolloverValue = 0;
    
    if (typeof meterRoundDetails !== 'undefined') {
    
        const meters = meterRoundDetails.meters;
        
        // Get the name, utility type, serial number and archived status for this meter
        meterName = dataFunctions.getMeterDataItem(meters, 'name', meterId);
        meterIsArchived = dataFunctions.getMeterDataItem(meters, 'archived', meterId);
        
        const type = dataFunctions.getMeterDataItem(meters, 'utilityType', meterId).toLowerCase();
        meterUtilityType = type.charAt(0).toUpperCase() + type.slice(1);
        
        const serialNo = dataFunctions.getMeterDataItem(meters, 'serialNumber', meterId);
        
        if (serialNo !== "" && serialNo !== null) {
            meterSerialNo = serialNo;
        }
        
        const meterProperties = meterRoundDetails.meterProperties;
           
        // Get the data feed unti type for this meter
        const unitType = dataFunctions.getDataFeedUnitType(meterProperties, meterId);
        
        if (unitType !== null) {
            meterDataFeedUnit = unitType;
        }
        
        // Get previous reading for this meter
        const reading = dataFunctions.getPreviousReading(meterRoundDetails.latestMeterReadings, meterId);

        if (reading !== null) {
            previousReading = reading;
        }
        
        // Get isCumulative flag for this meter
        const isCumulative = dataFunctions.getMeterPropertyByMeterId(meterProperties, meterId, 'cumulative');

        if (isCumulative !== null) {
            meterIsCumulative = isCumulative;
        }
        
        // Get rollover value for this meter
        const rolloverValue = dataFunctions.getMeterPropertyByMeterId(meterProperties, meterId, 'rolloverValue');

        if (rolloverValue !== null) {
            meterRolloverValue = rolloverValue;
        }
    }
    
    return {
        loading: state.ajaxCallsInProgress > 0,
        meterId,
        meterRoundId,
        meterName,
        meterUtilityType,
        meterDataFeedUnit,
        meterRoundDetails,
        meterSerialNo,
        meterIsArchived,
        previousReading,
        readingDate,
        meterRoundProgress,
        meterRoundProgressLoaded,
        meterIsIgnored,
        meterIsCumulative,
        meterRolloverValue
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterReadingContainer);


