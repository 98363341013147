import { UnauthorisedError, NetworkError } from 'JS/errors/errors';

import * as httpStatusCodes from 'JS/util/httpStatusCodes';
import * as appText from 'JS/language/en/appText';

function getFullUrl(url) {
    return process.env.API_URL + url;
}

function getHeaders(token){
    return {
        'Authorization': 'Bearer '+token,
        'Content-Type': 'application/json'
    };
}

export function logout(token) {
    if(!token) {
        return Promise.reject(new UnauthorisedError("No token"));
    }

    return fetch(process.env.LOGOUT_URL, {
        method: 'POST',
        credentials: "include",
        headers: {
            'Authorization': 'Bearer '+ token
        }
    });
}
export function get(token, url) {
    if(!token) {
        return Promise.reject(new UnauthorisedError("No token"));
    }
    
    return fetch(getFullUrl(url), {
        headers: getHeaders(token)
    }).then(response => {
        return handleResponseStatusCodes(response);
    });
}

export function post(token, url, body) {
    if(!token) {
        return Promise.reject(new UnauthorisedError("No token"));
    }
    
    return fetch(getFullUrl(url), {
        method: "POST",
        headers: getHeaders(token),
        body: JSON.stringify(body)
    }).then(response => {
        return handleResponseStatusCodes(response);
    });
}

function handleResponseStatusCodes(response) {
    
    switch(response.status) {
        case httpStatusCodes.UNAUTHORIZED:
            return Promise.reject(new UnauthorisedError("Bad token", response));

        default:
            if(!response.ok){
                return Promise.reject(new NetworkError(response, response.status));
            }

            return response.json();
    }
}

export function isCached(url) {
    return caches.open(process.env.API_CACHE_NAME)
    .then(function(cache) {
      return cache.match(getFullUrl(url))
      .then(function(response) {
        return !!response;
      });
    });
}

export function clearCache(url) {
    return caches.open(process.env.API_CACHE_NAME)
    .then(function(cache) {
      return cache.delete(getFullUrl(url));
    });
}