import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestDebugInformation } from 'JS/actions/debugActions';
import * as appText from 'JS/language/en/appText';

const DebugMenuItem = ({debug}) => {
    return (
        <a
            className="bm-item"
            onClick={debug}
            href=""
        >{appText.DEBUG_BUTTON_TEXT}</a>
    );
};

DebugMenuItem.propTypes = {
    debug: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    debug: requestDebugInformation
};

export default connect(null, mapDispatchToProps)(DebugMenuItem);

