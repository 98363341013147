import * as types from 'JS/actions/actionTypes';
import initialState from './initialState';

export default function siteReducer(state = initialState.sites, action) {
    switch(action.type) {
        case types.GET_SITES_SUCCESS: {
            return [...state.filter(site => site.contractId != action.contractId), ...action.sites];
        }
        
        case types.GET_SITE_SUCCESS: {
            return [...state.filter(site => site.siteId != action.siteId), action.site];
        }
        
        default:
            return state;
    }
}