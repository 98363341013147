import React from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/reading/meterReadingHeader.css';

import * as appText from 'JS/language/en/appText';

function MeterReadingHeader(props) {
    return (
        <div className={css.meterReadingHeader}>
            <div><span>{appText.UTILITY_TYPE_TEXT}</span>{props.meterUtilityType}</div>
            <div><span>{appText.DATA_FEED_TEXT}</span>{props.meterDataFeedUnit}</div>
            <div><span>{appText.SERIAL_NUMBER_TEXT}</span>{props.meterSerialNo}</div>
        </div>
    );
}

MeterReadingHeader.propTypes = {
    meterUtilityType: PropTypes.string.isRequired,
    meterSerialNo: PropTypes.string.isRequired,
    meterDataFeedUnit: PropTypes.string.isRequired
};

export default MeterReadingHeader;
  