import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Datetime from 'react-datetime';

import css from 'CSS/components/dialog/customContent/dateDialogPicker.css';

export default class DateDialogPicker extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {
            disabled: this.props.disabled,
            valid: true
        };
        
        this.onDateChange = this.onDateChange.bind(this);
    }
    
    onDateChange(event) {
        // This event is triggered by the react-datetime plugin
        // The event parameter will either be a moment date or a string
        // if the date is invalid.

        if (typeof event === 'string' 
            || event.year() < 1000 
            || event.year() >= 9999) {
            //TODO: Stop user moving on with invalid date
            this.setState({
                valid: false
            });

            return this.props.updateDateState(null);
        }

        this.setState({
            valid: true
        });
        
        // At this point we have a Moment date.
        // Calling format() formats the date into an ISO date format, eg, 2022-11-28T23:59:59+00:00
        const date = event.format();
        return this.props.updateDateState(date);
    }
   
    render() {

        const timeFormat = "HH:mm:ss";
        const dateFormat = "DD/MM/YYYY";

        return (

            <div className={css.dateContainer + (this.state.valid ? "" : " "+css.dateInvalid)}>
    
                {!this.props.disabled && <Datetime
                    open
                    onChange={this.onDateChange}
                    defaultValue={this.props.defaultDateTime}
                    inputProps={{ disabled: this.props.disabled }}
                    dateFormat={dateFormat}
                    timeFormat={timeFormat}
                    utc={true} />}
                            
             </div>
        );
    }
}

DateDialogPicker.propTypes = {
    disabled: PropTypes.bool.isRequired,
    updateDateState: PropTypes.func.isRequired,
    defaultDateTime: PropTypes.instanceOf(Date).isRequired
};


