import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import css from 'CSS/components/meterRound/meterRound.css';
import * as meterRoundActions from 'JS/actions/meterRoundActions';
import * as meterRoundProgressActions from 'JS/actions/meterRoundProgressActions';
import MeterRoundProgress, { MeterRoundProgressStatus } from 'JS/dto/meterRoundProgress';

import GoToRound from 'JS/components/meterRound/goToRound'; // eslint-disable-line import/no-named-as-default
import LoadingGraphic from 'JS/components/ui/loadingGraphic';
import PanelContainer from 'JS/components/ui/panelContainer';
import MeterRoundProgressInfo from 'JS/components/meterRound/meterRoundProgressInfo';

import ModalDialog from 'JS/components/dialog/modalDialog';
import DialogHeader from 'JS/components/dialog/dialogHeader';
import MessageDialogContent from 'JS/components/dialog/messageDialogContent';
import OkCancelDialogFooter from 'JS/components/dialog/okCancelDialogFooter';

import * as appText from 'JS/language/en/appText';
import toastr from 'toastr';
import * as dataFunctions from 'JS/util/dataFunctions';

export class MeterRoundListItem extends Component {
    
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            dialogOpen: false
        };
        
        this.downloadRound = this.downloadRound.bind(this);
        this.endRoundClick = this.endRoundClick.bind(this);
        this.endRound = this.endRound.bind(this);
        this.ignoreEndRoundDialog = this.ignoreEndRoundDialog.bind(this);
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        
        const meterRoundId = this.props.meterRoundDetails.meterRoundId;
        
        if (typeof meterRoundId === 'undefined') {
            return;
        }
        
        if (meterRoundId === nextProps.meterRoundDetails.meterRoundId) {
            this.setState({
                loadingDetails: false
            });
        }
    }
    
    downloadRound() {

        document.getElementsByClassName('loadingGraphicContainer')[0].style.display = 'none';
        
        this.setState({
            loadingDetails: true
        });

        this.props.actions.clearMeterRoundDetailsCache(this.props.meterRound.meterRoundId)
       .then(() => this.props.actions.downloadMeterRoundDetails(this.props.meterRound.meterRoundId))
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_METER_ROUND_DETAILS_TEXT);
                this.setState({
                    loadingDetails: false
                });
            });
    }
  
    endRoundClick(){
        return this.endRound();
    }
    
    endRound(forceEnd) {
        return this.props.actions.deleteMeterRoundProgress(this.props.meterRound.meterRoundId, forceEnd)
            .then(() => this.props.actions.clearMeterRoundDetailsCache(this.props.meterRound.meterRoundId))
            .catch (exception => {
                
                if(typeof exception == "string"){
                    this.setState({
                        dialogOpen: true,
                        dialogMessage: exception
                    });
                }
            });
    }
    
    ignoreEndRoundDialog() {
        this.clearDialog();
        
        return this.endRound(true);
    }
    
    clearDialog() {
        this.setState({
            dialogOpen: false,
            dialogTitle: null,
            dialogMessage: null
        });
    }
    
    render() {
             
        const LOADING_STYLES = {
            'width': '36px',
            'marginLeft': '5px',
            'marginTop': '-3px'
        };
      
        const detailsNextReading = this.props.meterRoundDetails.nextReading;
        const roundNextReading = this.props.meterRound.nextReading;

        // Once the meter round details are loaded, use this date as it will be fresh data
        const nextReadingDate = (detailsNextReading == null) ? roundNextReading : detailsNextReading;

        return (
            <li>
                {this.state.dialogOpen &&
                    <ModalDialog id ={"ignoreEndRoundDialog" + this.props.meterRound.meterRoundId} open={this.state.dialogOpen}>
                        <DialogHeader  dialogTitle={appText.WARNING_DIALOG_TITLE} />
                        <MessageDialogContent dialogMessage={this.state.dialogMessage} />
                        <OkCancelDialogFooter onOkClick={this.ignoreEndRoundDialog} />
                    </ModalDialog>
                }
                
                <PanelContainer>
                
                    <div className={css.meterRoundListItemContainer}>
                        <div className="meterRoundHeading engiePrimaryColour">{this.props.meterRound.name}</div>

                        {!this.props.isStarted &&
                            <div className={css.meterRoundStatus}>Next Reading Date: {new Date(nextReadingDate).toLocaleDateString()}</div>
                        }

                        {this.props.isStarted &&
                            <MeterRoundProgressInfo totalMetersUnRead={this.props.totalMetersUnRead}
                                                    totalMetersInRound={this.props.totalMetersInRound}
                                                    totalMetersIgnored={this.props.totalMetersIgnored}
                                                    roundComplete={this.props.roundComplete}/>
                        }

                        <button type="button"
                                className={css.downloadRoundButton + " engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour"}
                                onClick={this.downloadRound}
                                disabled={this.props.isReady ? "disabled" : ""}>{appText.DOWNLOAD_BUTTON_TEXT}
                        </button>

                        {this.props.isReady &&
                            <GoToRound nextReadingDate={nextReadingDate} meterRoundId={this.props.meterRound.meterRoundId} />
                        }

                        {this.props.isStarted &&
                            <button type="button" id="endRoundButton"
                                    className={css.downloadRoundButton + " engie-btn btn-secondary btn-rounded engieSecondaryBackgroundColour engieSecondaryHoverBgColour"}
                                    onClick={this.endRoundClick}>{appText.END_ROUND_BUTTON_TEXT}
                            </button>
                        }

                        {this.state.loadingDetails && <LoadingGraphic style={LOADING_STYLES} />}
                        <div className={css.downloadedText + " engiePrimaryColour"}>{this.props.message}</div>
                        
                    </div>
                    
                </PanelContainer>
            </li>
        );
    }
}

MeterRoundListItem.propTypes = {
    meterRound: PropTypes.object.isRequired,
    meterRoundDetails: PropTypes.object.isRequired,
    isReady: PropTypes.bool.isRequired,
    isStarted: PropTypes.bool.isRequired,
    actions: PropTypes.object.isRequired,
    message: PropTypes.string,
    meterRoundId: PropTypes.string,
    totalMetersUnRead: PropTypes.number.isRequired,
    totalMetersInRound: PropTypes.number.isRequired,
    totalMetersIgnored: PropTypes.number.isRequired,
    roundComplete: PropTypes.bool.isRequired
};

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(Object.assign({}, meterRoundActions, meterRoundProgressActions), dispatch)
    };
}

function mapStateToProps(state, ownProps) {
    
    const meterRoundId = ownProps.meterRound.meterRoundId;
    const meterRoundDetails = state.meterRoundDetails[meterRoundId];
    const meterRoundProgress = state.meterRoundProgress ? state.meterRoundProgress[meterRoundId] : null;
    const meterRoundDownloaded = state.meterRoundDownloadStatus ? state.meterRoundDownloadStatus[meterRoundId] : false;
    const isReady = meterRoundProgress != null && !!meterRoundDownloaded;
    const isStarted = isReady && MeterRoundProgress.isStarted(meterRoundProgress);
    
    // Meter progress info for display
    const totalMetersInRound = dataFunctions.getTotalActiveMetersInRound(meterRoundDetails);
    const meterReadings = (meterRoundProgress && meterRoundProgress.readings) ? meterRoundProgress.readings: null;
    const totalMetersRead = dataFunctions.getTotalReadMetersInRound(meterReadings);
    const totalMetersIgnored = dataFunctions.getTotalIgnoredMetersInRound(meterReadings);
    const totalMetersUnRead = totalMetersInRound - totalMetersRead;
    const roundComplete = (totalMetersUnRead === 0 && totalMetersIgnored === 0);
         
    return {
        roundComplete,
        totalMetersIgnored,
        totalMetersInRound,
        totalMetersUnRead,
        meterRoundDetails: meterRoundDetails ? meterRoundDetails : {},
        isReady,
        isStarted,
        message: isReady && !isStarted ? appText.METER_ROUND_READY_TO_START_TEXT : null
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterRoundListItem);
