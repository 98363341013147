// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.dateDialogPicker__dateContainer___rK9Qj {\n    height: 80px;\n}\n\n.dateDialogPicker__dateInvalid___sn4HL, .dateDialogPicker__dateInvalid___sn4HL input[type=text] {\n    color: #E10019;\n}\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/dialog/customContent/dateDialogPicker.css"],"names":[],"mappings":";AACA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;AAClB","sourcesContent":["\n.dateContainer {\n    height: 80px;\n}\n\n.dateInvalid, .dateInvalid input[type=text] {\n    color: #E10019;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dateContainer": "dateDialogPicker__dateContainer___rK9Qj",
	"dateInvalid": "dateDialogPicker__dateInvalid___sn4HL"
};
export default ___CSS_LOADER_EXPORT___;
