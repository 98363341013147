// buttons
export const DOWNLOAD_BUTTON_TEXT = "Download";
export const START_BUTTON_TEXT = "Start";
export const CONTINUE_BUTTON_TEXT = "Continue";
export const END_ROUND_BUTTON_TEXT = "End";
export const TAKE_READING_BUTTON_TEXT = "Take Reading";
export const READING_TAKEN_BUTTON_TEXT = "Completed";
export const IGNORE_BUTTON_TEXT = "Ignore";
export const REINSTATE_BUTTON_TEXT = "Reinstate";
export const BACK_TO_METER_ROUND_LIST_BUTTON_TEXT = "Back To Meter Round List";
export const LOG_IN_BUTTON_TEXT = "Login";
export const LOG_OUT_BUTTON_TEXT = "Logout";
export const SAVE_READING_BUTTON_TEXT = "Save Reading";
export const BACK_TO_METER_LIST = "BACK TO METER LIST";
export const DEBUG_BUTTON_TEXT = "Debug";
export const RETRY_BUTTON_TEXT = "Retry Saving";

// Screen titles
export const SELECT_METER_ROUND_TEXT = "Select Meter Round";
export const ACTIVE_METER_ROUND_TEXT = "Active Meter Round";
export const SET_DATE_TEXT = "Set Reading Date";

// Error message text
export const FAILED_TO_GET_CONTRACTS_TEXT = "Failed to get contracts";
export const FAILED_TO_GET_CONTRACT_TEXT = "Failed to get contract";
export const FAILED_TO_GET_SITES_TEXT = "Failed to get sites";
export const FAILED_TO_GET_SITE_TEXT = "Failed to get site";
export const FAILED_TO_GET_METER_ROUNDS_TEXT = "Failed to get meter rounds";
export const FAILED_TO_GET_METER_ROUND_TEXT = "Failed to get meter round";
export const FAILED_TO_GET_METER_ROUND_DETAILS_TEXT = "Failed to get meter round details";
export const FAILED_TO_START_METER_ROUND_TEXT = "Failed to start meter round";
export const METER_ROUND_NEEDS_DOWNLOADING = "You must download a meter round before starting it";
export const METER_ROUND_NEEDS_DOWNLOADING_BEFORE_READING = "You must download a meter round before taking a reading";
export const NO_DATA = "No Data";
export const METER_IS_ARCHIVED_TEXT = "This meter is archived";
export const GENERIC_UI_ERROR_TEXT = "Error loading this page component";
export const METER_ROUND_ALREADY_STARTED = "Meter round already started";
export const METER_ROUND_NO_READING_FOUND = "Meter round already started";
export const OFFLINE_ACTION_ERROR_TEXT = "This action could not be completed offline";
export const NOT_ALL_READINGS_UPLOADED = "Not all readings for this meter round have been uploaded, are you sure you wish to end this round and lose these readings?";
export const METER_IS_IGNORED_TEXT = "This meter has been ignored";
export const READING_ERROR_MESSAGE = "There was an error updating reading. Please try again";
export const INVALID_DATE_ENTERED = "Invalid date selected";

// General
export const C3NTINEL_LOGO_TEXT = "C3NTINEL";
export const C3NTINEL_LOGO_SUP_TEXT = "®";
export const MOBILE_METER_READING_TEXT = "Mobile Meter Reading";
export const METER_ROUND_READY_TO_START_TEXT = "METER ROUND DOWNLOADED AND READY TO START";
export const LOADING_TEXT = "Loading...";
export const ENTER_METER_READ_TEXT = "Enter meter read";
export const NEW_READING_TEXT = "New Reading";
export const LAST_READING_TEXT = "Reading";
export const NO_READINGS_AVAILABLE = "N/A";
export const ARCHIVED_METER_PREFIX = "Archived - ";
export const IGNORED_METER_HIGHLIGHT_TEXT = "This meter has been ignored";
export const PARTIALLY_COMPLETED_TEXT = "This round is partially completed";
export const COMPLETED_TEXT = "This round is completed";
export const METER_TEXT = "meter";
export const METERS_TEXT = "meters";
export const REMAINING_TEXT = " remaining of ";
export const WITH_TEXT = ", with ";
export const IGNORED_TEXT = " ignored";
export const UTILITY_TYPE_TEXT = "Utility Type: ";
export const DATA_FEED_TEXT = "Data Feed Unit: ";
export const SERIAL_NUMBER_TEXT = "Serial Number: ";
export const SAVING_TEXT = "Trying to save...";



//DB
export const INDEX_DB_ERROR_TEXT = "Failed to retieve meter round appilcation data";
export const INDEX_DB_FAILED_TO_OPEN = "Failed to connect to meter round appilcation data";
export const INDEX_DB_NOT_SUPPORTED_TEXT = "This browser does not support indexedDB";

// Dialogs
export const WARNING_DIALOG_TITLE = "Warning";
export const ERROR_DIALOG_TITLE = "Error";
export const READING_NOT_VALID_INPUT_ERROR_MESSAGE = "Reading must be a valid number greater than zero";
export const READING_GREATER_THAN_ROLLOVER_INPUT_ERROR_MESSAGE = "Reading can not be greater than the rollover amount";
export const READING_IS_ROLLOVER_WARNING_MESSAGE = "A rollover has been detected. Is this correct?";
export const READING_INPUT_WARNING_MESSAGE = "The value you have entered overlaps with the previous value. Do you want to save and continue?";
export const SET_CURRENT_CLOCK_TIME = "Set A Custom Date";
export const USE_CURRENT_TIME = "Set Current Clock Time";
export const READING_IS_DUPLICATE_ERROR_MESSAGE = "Reading was not updated as it has already been added for this date";
export const READING_NOT_PROCESSED_ERROR_MESSAGE = "There was an error processing the submitted reading";
export const DEBUG_SUCCESS_TEXT = "Debug information copied to the clipboard, please paste into an email and send to info@c3ntinel.com";