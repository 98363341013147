import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import toastr from 'toastr';

import MeterRoundListItem from './meterRoundListItem'; // eslint-disable-line import/no-named-as-default
import * as meterRoundActions from 'JS/actions/meterRoundActions';

import css from 'CSS/components/meterRound/meterRound.css';
import * as appText from 'JS/language/en/appText';

class MeterRoundList extends Component {
    
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            meterRounds: props.meterRounds
        };
    }
    
    componentDidMount() {
        this.props.actions.getMeterRounds(this.props.siteId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_METER_ROUNDS_TEXT);
            });
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.meterRounds.length !== nextProps.meterRounds.length) {
            this.setState({
                meterRounds: nextProps.meterRounds
            });
        }
    }
    
    render() {
        
        const meterRounds = this.state.meterRounds.map(meterRound =>
            <MeterRoundListItem key={meterRound.meterRoundId} meterRound={meterRound}/>
        );
        
        return (
                       
            <ul className={css.meterRoundListItem}>
                {meterRounds}
            </ul>
        );
    }
}
       
MeterRoundList.propTypes = {
    actions: PropTypes.object.isRequired,
    meterRounds: PropTypes.array.isRequired,
    siteId: PropTypes.number.isRequired
};

function mapStateToProps(state, ownProps){
    const meterRounds = state.meterRounds.filter(round => round.siteId === ownProps.siteId);
    return {
        meterRounds: meterRounds ? meterRounds : []
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(meterRoundActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MeterRoundList);
