import * as types from './actionTypes';
import contractApi from 'JS/service/api/contractApi';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';

export function getContractsSuccess(contracts) {
    return { type: types.GET_CONTRACTS_SUCCESS, contracts };
}

export function getContractSuccess(contract, contractId) {
    return {
        type: types.GET_CONTRACT_SUCCESS,
        contract,
        contractId
    };
}

export function getContracts() {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return contractApi.getContracts(getState().auth.token).then(response => {
            dispatch(getContractsSuccess(response._embedded.contracts));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}

export function getContract(contractId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return contractApi.getContract(getState().auth.token, contractId).then(contract => {
            dispatch(getContractSuccess(contract, contractId));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}