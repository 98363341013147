// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.splashBackground__splashBackground___w8fUM {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    bottom: 0;\n    margin-top: 42vh;\n    z-index: -1;\n}\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/splashBackground.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,SAAS;IACT,gBAAgB;IAChB,WAAW;AACf","sourcesContent":["\n.splashBackground {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    bottom: 0;\n    margin-top: 42vh;\n    z-index: -1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splashBackground": "splashBackground__splashBackground___w8fUM"
};
export default ___CSS_LOADER_EXPORT___;
