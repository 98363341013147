import React from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/ui/notificationMessage.css';

function NotificationMessage(props) {
      
    return (
            
        <div className={css.notificationText} style={props.colourClass}>
            <i className={css.notificationIcon + " engie-icons"}>{props.iconName}</i>
            &nbsp;&nbsp;{props.text}
        </div>
    );
}

NotificationMessage.propTypes = {
    colourClass: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    iconName: PropTypes.string.isRequired
};

export default NotificationMessage;


