import React, { Component } from 'react';
import css from 'CSS/components/ui/welcomeText.css';
import * as appText from 'JS/language/en/appText';

export default class WelcomeText extends Component {
    render() {
        return (
            <div>
                <div className={css.c3ntinel + " row justify-content-center"}>
                    {appText.C3NTINEL_LOGO_TEXT}<sup>{appText.C3NTINEL_LOGO_SUP_TEXT}</sup>
                </div>
                <div className={css.appName + " row justify-content-center"}>
                    {appText.MOBILE_METER_READING_TEXT}
                </div>
            </div>
        );
    }
}