import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/dialog/dialogFooter.css';

function OkDialogFooter() {
    return (
       
       <div className="modal-footer">
            <button
                type="button"
                className="engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour"
                data-dismiss="modal">
                OK
            </button>
        </div>
    );
}

export default OkDialogFooter;