// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../../assets/images/container/splashTablet.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../../../assets/images/container/splashPhone.jpg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../../../../assets/images/container/splashDesktop.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.splashImage__splashImage___cKCRk {\n    background-size: cover;\n    min-width: 360px;\n    resize: both;\n    width: 100%;\n}\n\nhtml.tablet .splashImage__splashImage___cKCRk {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    height: 42vh;\n    background-position-y: center;\n}\n\nhtml.phone .splashImage__splashImage___cKCRk {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    height: 42vh;\n    background-position-y: center;\n}\n\nhtml.desktop .splashImage__splashImage___cKCRk {\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    height: 53vh;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html.tablet .splashImage__splashImage___cKCRk {\n       height: 50vh;\n    }\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/splashImage.css"],"names":[],"mappings":";AACA;IACI,sBAAsB;IACtB,gBAAgB;IAChB,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,yDAA2E;IAC3E,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,yDAA0E;IAC1E,YAAY;IACZ,6BAA6B;AACjC;;AAEA;IACI,yDAA4E;IAC5E,YAAY;AAChB;;AAEA;;IAEI;OACG,YAAY;IACf;AACJ","sourcesContent":["\n.splashImage {\n    background-size: cover;\n    min-width: 360px;\n    resize: both;\n    width: 100%;\n}\n\nhtml:global(.tablet) .splashImage {\n    background-image: url(../../../../assets/images/container/splashTablet.jpg);\n    height: 42vh;\n    background-position-y: center;\n}\n\nhtml:global(.phone) .splashImage {\n    background-image: url(../../../../assets/images/container/splashPhone.jpg);\n    height: 42vh;\n    background-position-y: center;\n}\n\nhtml:global(.desktop) .splashImage {\n    background-image: url(../../../../assets/images/container/splashDesktop.jpg);\n    height: 53vh;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html:global(.tablet) .splashImage {\n       height: 50vh;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"splashImage": "splashImage__splashImage___cKCRk"
};
export default ___CSS_LOADER_EXPORT___;
