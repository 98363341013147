import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { requestLogout } from 'JS/actions/apiLoginActions';

import * as appText from 'JS/language/en/appText';

const LogoutMenuItem = ({logout}) => {
    return (
        <a
            onClick={logout}
            className="bm-item"
            href=""
        >{appText.LOG_OUT_BUTTON_TEXT}</a>
    );
};

LogoutMenuItem.propTypes = {
    logout: PropTypes.func.isRequired
};

function logoutClick(event) {
    event.preventDefault();

    return requestLogout();
}

const mapDispatchToProps = {
    logout: logoutClick
};

export default connect(null, mapDispatchToProps)(LogoutMenuItem);

