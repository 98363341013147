// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meterRoundHeader__titleContainer___PJp4_ {\n    margin-bottom: 15px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/meterRound/meterRoundHeader.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB","sourcesContent":[".titleContainer {\n    margin-bottom: 15px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"titleContainer": "meterRoundHeader__titleContainer___PJp4_"
};
export default ___CSS_LOADER_EXPORT___;
