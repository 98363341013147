import * as types from './actionTypes';
import siteApi from 'JS/service/api/siteApi';
import { beginAjaxCall, ajaxCallError } from 'JS/actions/ajaxStatusActions';

export function getSitesSuccess(sites, contractId) {
    return {
        type: types.GET_SITES_SUCCESS,
        sites,
        contractId
    };
}

export function getSiteSuccess(site, siteId) {
    return {
        type: types.GET_SITE_SUCCESS,
        site,
        siteId
    };
}

export function getSites(contractId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return siteApi.getSites(getState().auth.token, contractId).then(response => {
            dispatch(getSitesSuccess(response._embedded.sites, contractId));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}

export function getSite(siteId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return siteApi.getSite(getState().auth.token, siteId).then(site => {
            dispatch(getSiteSuccess(site, siteId));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}