import * as types from 'JS/actions/actionTypes';
import initialState from './initialState';

export default function meterRoundDownloadStatusReducer(state = initialState.meterRoundDownloadStatus, action) {
    switch(action.type) {
        case types.LOAD_DOWNLOADED_STATUS_OF_METER_ROUNDS_SUCCESS: {
            let meterRoundDownloadStatusStore = {};
            
            action.meterRoundDownloadStatus.reduce((obj, item) => {
                obj[item["meterRoundId"]] = item.isDownloaded;
                return obj;
            }, meterRoundDownloadStatusStore);
            
            return Object.assign({}, state, meterRoundDownloadStatusStore);
        }
        
        case types.DOWNLOAD_METER_ROUND_DETAILS_SUCCESS: {
            let meterRoundDownloadStatusStore = {};
            
            meterRoundDownloadStatusStore[action.meterRoundId] = true;
            
            return Object.assign({}, state, meterRoundDownloadStatusStore);
        }
        
        default:
            return state;
    }
}

