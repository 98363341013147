import React, { Component } from 'react';
import PropTypes from 'prop-types';

import * as appText from 'JS/language/en/appText';

class IgnoreMeterToggle extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.toggleIgnoredState = this.toggleIgnoredState.bind(this);
    }
    
    toggleIgnoredState() {
        this.props.onIgnoreToggle(!this.props.isIgnored);
    }
    
    render() {
        return (
                <button
                    className="engie-btn btn-secondary btn-rounded engieSecondaryBackgroundColour engieSecondaryHoverBgColour"
                    onClick={this.toggleIgnoredState}>{ this.props.isIgnored ? appText.REINSTATE_BUTTON_TEXT : appText.IGNORE_BUTTON_TEXT}
                </button>
        );
    }
}

IgnoreMeterToggle.propTypes = {
    isIgnored: PropTypes.bool.isRequired,
    onIgnoreToggle: PropTypes.func.isRequired
};

export default IgnoreMeterToggle;
