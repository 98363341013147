import { get, isCached, clearCache } from './baseApi';

const METER_ROUND_DETAILS_BASE_PATH = '/meterround/details/';

class MeterRoundApi {
    static getMeterRounds(token, siteId) {
        return get(token, '/meterround/bysite/' + siteId);
    }
    
    static getMeterRound(token, meterRoundId) {
        return get(token, '/meterround/' + meterRoundId);
    }
    
    static areMeterRoundDetailsDownloaded(roundId) {
        return isCached(METER_ROUND_DETAILS_BASE_PATH + roundId);
    }
    
    static clearMeterRoundDetailsCache(roundId) {
        return clearCache(METER_ROUND_DETAILS_BASE_PATH + roundId);
    }
    
    static getMeterRoundDetails(token, roundId) {
        return get(token, METER_ROUND_DETAILS_BASE_PATH + roundId);
    }
}

export default MeterRoundApi;