import * as types from 'JS/actions/actionTypes';
import { beginAjaxCall, ajaxCallError } from 'JS/actions/ajaxStatusActions';
import { storeMeterRoundProgressData } from 'JS/actions/meterRoundProgressActions';
import MeterRoundProgress from 'JS/dto/meterRoundProgress';

import meterRoundApi from 'JS/service/api/meterRoundApi';

export function getMeterRoundsSuccess(meterRounds, siteId) {
    return {
        type: types.GET_METER_ROUNDS_SUCCESS,
        meterRounds,
        siteId
    };
}

export function getMeterRoundSuccess(meterRound, meterRoundId) {
    return {
        type: types.GET_METER_ROUND_SUCCESS,
        meterRound,
        meterRoundId
    };
}

export function getMeterRoundDetailsSuccess(meterRoundDetails, meterRoundId) {
    return {
        type: types.GET_METER_ROUND_DETAILS_SUCCESS,
        meterRoundDetails,
        meterRoundId
    };
}

export function downloadMeterRoundDetailsSuccess(meterRoundId, meterRoundProgress) {
    return {
        type: types.DOWNLOAD_METER_ROUND_DETAILS_SUCCESS,
        meterRoundId,
        meterRoundProgress
    };
}

export function getMeterRounds(siteId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return meterRoundApi.getMeterRounds(getState().auth.token, siteId).then(response => {
            dispatch(getMeterRoundsSuccess(response._embedded.meterRounds, siteId));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}

export function getMeterRound(meterRoundId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return meterRoundApi.getMeterRound(getState().auth.token, meterRoundId).then(meterRound => {
            dispatch(getMeterRoundSuccess(meterRound, meterRoundId));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}

export function downloadMeterRoundDetails(roundId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        return dispatch(getMeterRoundDetails(roundId))
            .then(() => dispatch(storeMeterRoundProgressData(roundId, MeterRoundProgress.create(roundId))))
            .then((storeAction) => dispatch(downloadMeterRoundDetailsSuccess(roundId, storeAction.progress)))
            .catch(error => {
                dispatch(ajaxCallError());
                throw error;
            });
    };
}

export function getMeterRoundDetails(roundId) {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        return meterRoundApi.getMeterRoundDetails(getState().auth.token, roundId)
        .then(meterRoundDetails => {
           dispatch(getMeterRoundDetailsSuccess(meterRoundDetails, roundId));
       }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}

export function clearMeterRoundDetailsCache(roundId){
    return function(dispatch, getState) {
        return meterRoundApi.clearMeterRoundDetailsCache(roundId);
    };
}

export function loadDownloadedStatusOfMeterRoundsSuccess(meterRoundDownloadStatus) {
    return {
        type: types.LOAD_DOWNLOADED_STATUS_OF_METER_ROUNDS_SUCCESS,
        meterRoundDownloadStatus
    };
}

export function loadDownloadedStatusOfMeterRounds() {
    return function(dispatch, getState) {
        dispatch(beginAjaxCall());
        
        const progressItems = getState().meterRoundProgress;
        let downloadCheckPromises = [];
        for(const i in progressItems){
            const current = progressItems[i];
            downloadCheckPromises.push(
                meterRoundApi.areMeterRoundDetailsDownloaded(current.meterRoundId)
                .then((isDownloaded) => {
                    return Promise.resolve({
                        meterRoundId: current.meterRoundId,
                        isDownloaded
                    });
                })
            );
        }
        
        return Promise.all(downloadCheckPromises)
        .then((meterRoundDownloadState) => {
            return dispatch(loadDownloadedStatusOfMeterRoundsSuccess(meterRoundDownloadState));
        }).catch(error => {
            dispatch(ajaxCallError());
            throw error;
        });
    };
}