import * as types from 'JS/actions/actionTypes';
import initialState from './initialState';

export default function contractReducer(state = initialState.contracts, action) {
    switch(action.type) {
        case types.GET_CONTRACTS_SUCCESS: {
            return action.contracts;
        }
        
        case types.GET_CONTRACT_SUCCESS: {
            return [...state.filter(contract => contract.contractId != action.contractId), action.contract];
        }
        
        default:
            return state;
    }
}