import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NotificationMessage from 'JS/components/ui/notificationMessage';

import * as appText from 'JS/language/en/appText';

export default class ErrorBoundary extends Component {
        
    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error) {
        
        // Display fallback UI
        this.setState({
            hasError: true
        });
    }

    render() {
        
        const engieRed = {
            color: '#E10019'
        };
        
        if (this.state.hasError) {
            
            // You can render any custom fallback UI
            return <NotificationMessage colourClass={engieRed}
                                        text={appText.GENERIC_UI_ERROR_TEXT}
                                        iconName="report_problem" />;
        }
        
        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.object.isRequired
};