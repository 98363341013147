import React, { Component } from 'react';
import PropTypes from 'prop-types';

import ScreenName from 'JS/components/ui/screenName';
import MeterReadingHeader from 'JS/components/reading/meterReadingHeader';
import PreviousReading from 'JS/components/reading/previousReading';
import MeterReadingPanel from 'JS/components/reading/meterReadingPanel'; // eslint-disable-line import/no-named-as-default
import BackLink from 'JS/components/ui/backLink';
import NotificationMessage from 'JS/components/ui/notificationMessage';
import ErrorBoundary from 'JS/errors/errorBoundary';

import css from 'CSS/pages/meterReadingPage.css';

import * as appText from 'JS/language/en/appText';
import * as paths from 'JS/util/paths';

class MeterReadingPage extends Component {
          
    render() {
        
        const engieRed = {
            color: '#E10019'
        };
        
        let readingPanel = <MeterReadingPanel
                                readingDate={this.props.readingDate}
                                meterRoundId={this.props.meterRoundId}
                                meterId={this.props.meterId}
                                meterIsCumulative={this.props.meterIsCumulative}
                                meterRolloverValue={this.props.meterRolloverValue}
                                previousReading={this.props.previousReading}
                            />;

        // If a meter is archived or ignored just show a message
        // instead of the meter reading input panel
        if (this.props.meterIsArchived || this.props.meterIsIgnored) {
            readingPanel = [];
        }
        if (this.props.meterIsArchived) {
            readingPanel.push(<NotificationMessage key="archived" colourClass={engieRed}
                                                   text={appText.METER_IS_ARCHIVED_TEXT}
                                                   iconName="report_problem" />);
        }
        
        if (this.props.meterIsIgnored) {
            readingPanel.push(<NotificationMessage key="ignored" colourClass={engieRed}
                                                   text={appText.METER_IS_IGNORED_TEXT}
                                                   iconName="report_problem" />);
        }
      
        return (
            <div>
                <div>
                    <BackLink url={paths.ROUND + "/" + this.props.meterRoundId}
                              linkText={appText.BACK_TO_METER_LIST} />
                    
                    <div className={css.noUserAddedImageContainer}/>
                </div>
                <div className={css.meterReadingContainer}>

                    <ScreenName loading={this.props.loading} screenNameText={this.props.meterName} />

                    <MeterReadingHeader meterUtilityType={this.props.meterUtilityType}
                                        meterSerialNo={this.props.meterSerialNo}
                                        meterDataFeedUnit={this.props.meterDataFeedUnit} />

                    <ErrorBoundary>
                        <PreviousReading previousReading={this.props.previousReading} />
                    </ErrorBoundary>

                    {readingPanel}
               
                </div>
            </div>
        );
    }
}

MeterReadingPage.propTypes = {
    loading: PropTypes.bool.isRequired,
    meterId: PropTypes.string.isRequired,
    meterUtilityType: PropTypes.string.isRequired,
    meterSerialNo: PropTypes.string.isRequired,
    meterDataFeedUnit: PropTypes.string.isRequired,
    previousReading: PropTypes.string.isRequired,
    meterName: PropTypes.string.isRequired,
    meterRoundId: PropTypes.string.isRequired,
    meterIsArchived: PropTypes.bool.isRequired,
    meterIsIgnored: PropTypes.bool.isRequired,
    meterIsCumulative: PropTypes.bool.isRequired,
    meterRolloverValue: PropTypes.number.isRequired,
    readingDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default MeterReadingPage;
