import React, { Component } from 'react';

import SplashLogo from 'JS/components/ui/splashLogo';
import SplashBackground from 'JS/components/ui/splashBackground';
import WelcomeText from 'JS/components/ui/welcomeText';
import Login from 'JS/components/login';
import Footer from 'JS/components/ui/footer';

export default class LoginPage extends Component {
    render() {
        
        document.getElementsByTagName('html')[0].classList.remove('loggedIn');
        return (
            <div className="appContainer justify-content-center">
                <SplashBackground/>
                <SplashLogo />
                <WelcomeText />
                <Login />
                <Footer />
            </div>
        );
    }
}