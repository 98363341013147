// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meterReadingInput__meterReadingInput___w_cl6 {\n    margin-bottom: 15px;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html.phone .meterReadingInput__meterReadingInput___w_cl6 {\n        margin-bottom: 7px;\n        float: left;\n        width: 49%;\n    }\n    \n    html.phone .meterReadingInput__meterReadingInput___w_cl6 input {\n        font-size: 12px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/reading/meterReadingInput.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;;IAEI;QACI,kBAAkB;QAClB,WAAW;QACX,UAAU;IACd;;IAEA;QACI,eAAe;IACnB;AACJ","sourcesContent":[".meterReadingInput {\n    margin-bottom: 15px;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html:global(.phone) .meterReadingInput {\n        margin-bottom: 7px;\n        float: left;\n        width: 49%;\n    }\n    \n    html:global(.phone) .meterReadingInput input {\n        font-size: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterReadingInput": "meterReadingInput__meterReadingInput___w_cl6"
};
export default ___CSS_LOADER_EXPORT___;
