// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".screenName__screenNameText___jaLGq {\n    font-size: 22px;\n    font-weight: 600;\n    display: inline-block;\n    text-transform: capitalize;      \n}\n\n.screenName__screenNameUnderline___QbxMZ {\n    height: 4px;\n    margin: 2px 0 10px 0;\n    width: 60px;\n    border-radius: 2px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/screenName.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,oBAAoB;IACpB,WAAW;IACX,kBAAkB;AACtB","sourcesContent":[".screenNameText {\n    font-size: 22px;\n    font-weight: 600;\n    display: inline-block;\n    text-transform: capitalize;      \n}\n\n.screenNameUnderline {\n    height: 4px;\n    margin: 2px 0 10px 0;\n    width: 60px;\n    border-radius: 2px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"screenNameText": "screenName__screenNameText___jaLGq",
	"screenNameUnderline": "screenName__screenNameUnderline___QbxMZ"
};
export default ___CSS_LOADER_EXPORT___;
