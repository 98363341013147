import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import toastr from 'toastr';

import Contract from './contract';

import css from 'CSS/components/contract/contractList.css';
import * as appText from 'JS/language/en/appText';
import * as contractActions from 'JS/actions/contractActions';


export class ContractList extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            contracts: props.contracts
        };
    }
    
    componentDidMount() {
        this.props.actions.getContracts()
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_CONTRACTS_TEXT);
            });
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.contracts.length !== nextProps.contracts.length) {
            this.setState({
                contracts: nextProps.contracts
            });
        }
    }
    
    render() {
        const contracts = this.props.contracts.map(contract =>
            <Contract key={contract.contractId} contract={contract}/>
        );
        return (

            <ul className={css.contractList}>
                {contracts}
            </ul>
        );
    }
}

ContractList.propTypes = {
    contracts: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps){
    return {
        contracts: state.contracts
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(contractActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);