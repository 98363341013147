import * as appText from 'JS/language/en/appText';

export const MeterRoundProgressStatus = {
    NOT_STARTED: {
        id:"NOT_STARTED"
    },
    IN_PROGRESS: {
        id:"IN_PROGRESS"
    }
};

export const SyncStatus = {
    PENDING: {
        id:"PENDING"
    },
    SUCCESS: {
        id:"SUCCESS"
    },
    FAILED: {
        id:"FAILED"
    }
};

class MeterRoundProgress {
    static create(meterRoundId) {
        return {
            meterRoundId,
            status : MeterRoundProgressStatus.NOT_STARTED
        };
    }
    
    static isStarted(roundProgress) {
        return roundProgress && roundProgress.status && roundProgress.status.id !== MeterRoundProgressStatus.NOT_STARTED.id;
    }
    
    static isIgnored(roundProgress, meterId) {
        return !!(roundProgress
                && roundProgress.readings
                && roundProgress.readings[meterId]
                && roundProgress.readings[meterId].isIgnored);
    }
    
    static startRound(roundProgress) {
        if(MeterRoundProgress.isStarted(roundProgress))
            throw appText.METER_ROUND_ALREADY_STARTED;
        
        return Object.assign({}, roundProgress, { status : MeterRoundProgressStatus.IN_PROGRESS });
    }
    
    static takeReading(roundProgress, meterId, reading, date, enteredDate) {
        let newReadings = Object.assign({}, roundProgress.readings);
        newReadings[meterId] = {
            reading,
            date,
            enteredDate,
            uploadStatus: SyncStatus.PENDING
        };
        
        return Object.assign({}, roundProgress, { readings : newReadings });
    }
    
    static changeReadingUploadStatus(roundProgress, meterId, status, errorMessage) {
        if(!roundProgress.readings || !roundProgress.readings[meterId]){
            throw appText.METER_ROUND_NO_READING_FOUND;
        }
        
        let changedReadings = JSON.parse(JSON.stringify(roundProgress.readings));
        
        changedReadings[meterId].uploadStatus = status;
        changedReadings[meterId].uploadStatusMessage = errorMessage;
        
        return Object.assign({}, roundProgress, { readings : changedReadings });
    }
    
    static ignoreMeter(roundProgress, meterId) {
        let changedReadings = roundProgress && roundProgress.readings
            ? JSON.parse(JSON.stringify(roundProgress.readings))
            : {};
        
        changedReadings[meterId] = {
            isIgnored : true
        };
        
        return Object.assign({}, roundProgress, { readings : changedReadings });
    }
   
    static reinstateMeter(roundProgress, meterId) {
        let changedReadings = roundProgress && roundProgress.readings
            ? JSON.parse(JSON.stringify(roundProgress.readings))
            : {};
        
        changedReadings[meterId] = {
            isIgnored : false
        };
        
        return Object.assign({}, roundProgress, { readings : changedReadings });
    }
    
    static setReadingDate(roundProgress, date) {
        let changedReadingDate = roundProgress && roundProgress.readingDate
            ? JSON.parse(JSON.stringify(roundProgress.readingDate))
            : {readingDate : date};
      
        
        return Object.assign({}, roundProgress, changedReadingDate);
    }
}

export default MeterRoundProgress;