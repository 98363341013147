import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import toastr from 'toastr';

import CollapsablePanel from 'JS/components/ui/collapsablePanel';
import MeterRoundList from 'JS/components/meterRound/meterRoundList';

import * as siteActions from 'JS/actions/siteActions';

import css from 'CSS/components/site/site.css';

import * as appText from 'JS/language/en/appText';


class SiteList extends Component {
    constructor(props, context) {
        super(props, context);
        
        this.state = {
            sites: props.sites
        };
    }
    
    componentDidMount() {
        this.props.actions.getSites(this.props.contractId)
            .catch (exception => {
                toastr.error(appText.FAILED_TO_GET_CONTRACTS_TEXT);
            });
    }
    
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(this.props.sites.length !== nextProps.sites.length) {
            this.setState({
                sites: nextProps.sites
            });
        }
    }
    
    render() {
        
        const sites = this.state.sites.map(site =>
            <CollapsablePanel key={site.siteId} id={site.siteId} title={site.name}>
                <MeterRoundList siteId={site.siteId}/>
            </CollapsablePanel>
        );
       
        return (
                     
            <ul className={css.siteListItem}>
                {sites}
            </ul>
                 
        );
    }
}

SiteList.propTypes = {
    sites: PropTypes.array.isRequired,
    contractId: PropTypes.number.isRequired,
    actions: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps){
    const sites = state.sites.filter(site => site.contractId === ownProps.contractId);
    return {
        sites: sites ? sites : []
    };
}

function mapDispatchToProps(dispatch){
    return {
        actions: bindActionCreators(siteActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteList);