// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.meterList__meterListButton___YaQNF {\n    text-align: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.meterList__navContainer___vD1bF {\n    text-align: center;\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/meter/meterList.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\n.meterListButton {\n    text-align: center;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.navContainer {\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterListButton": "meterList__meterListButton___YaQNF",
	"navContainer": "meterList__navContainer___vD1bF"
};
export default ___CSS_LOADER_EXPORT___;
