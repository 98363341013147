// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.collapsablePanel__panelName___ZeZ62 {\n    font-size: 15px;\n    font-weight: 200;\n}\n\n.collapsablePanel__iconArrow___Jcvx4 {\n    position: absolute;\n    right: 5px;\n}\n\n.collapsablePanel__headerButton___Hxqhh {\n    border-bottom: 1px solid #ccc;\n    cursor: pointer;\n    padding-bottom: 4px;\n}\n\n.collapsablePanel__panelContainer___U9PSD {\n    padding: 4px 0 3px 0;\n}\n\n.collapsablePanel__panelContent___Mi2ha {\n    font-size: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/collapsablePanel.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,6BAA6B;IAC7B,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.panelName {\n    font-size: 15px;\n    font-weight: 200;\n}\n\n.iconArrow {\n    position: absolute;\n    right: 5px;\n}\n\n.headerButton {\n    border-bottom: 1px solid #ccc;\n    cursor: pointer;\n    padding-bottom: 4px;\n}\n\n.panelContainer {\n    padding: 4px 0 3px 0;\n}\n\n.panelContent {\n    font-size: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelName": "collapsablePanel__panelName___ZeZ62",
	"iconArrow": "collapsablePanel__iconArrow___Jcvx4",
	"headerButton": "collapsablePanel__headerButton___Hxqhh",
	"panelContainer": "collapsablePanel__panelContainer___U9PSD",
	"panelContent": "collapsablePanel__panelContent___Mi2ha"
};
export default ___CSS_LOADER_EXPORT___;
