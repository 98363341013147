// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".contractList__contractList___XLVic {\n    list-style-type: none;\n    padding: 0 0 10px 0;\n}\n\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/contract/contractList.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,mBAAmB;AACvB","sourcesContent":[".contractList {\n    list-style-type: none;\n    padding: 0 0 10px 0;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contractList": "contractList__contractList___XLVic"
};
export default ___CSS_LOADER_EXPORT___;
