import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import AuthenticatedComponent from 'JS/util/authenticatedRoute';
import Footer from 'JS/components/ui/footer';
import Header from 'JS/components/ui/header';
import LoginContainer from 'JS/pageContainers/loginContainer';
import MeterRoundContainer from 'JS/pageContainers/meterRoundContainer';
import MainListingContainer from 'JS/pageContainers/mainListingContainer';
import MeterReadingContainer from 'JS/pageContainers/meterReadingContainer';
import ErrorBoundary from 'JS/errors/errorBoundary';
import Menu from 'JS/components/ui/menu';

import * as paths from 'JS/util/paths';

export default class Routes extends Component {
    render() {
        return <div>
            <Switch>
                <Route exact path={paths.LOGIN} component={props => <LoginContainer {...props} />} />
                <AuthenticatedComponent path="/">
                <div>
                    <div className="appContainer">
                        <Menu />
                        <Header />
                        <div className="appContentContainer">
                            <ErrorBoundary>
                                <Route exact path={paths.ROUNDS} component={props => <MainListingContainer {...props} />} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <Route exact path={paths.ROUND_WITH_METER_ROUND_ID_AND_METER_ID} component={props => <MeterRoundContainer {...props} />} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <Route exact path={paths.ROUND_WITH_METER_ROUND_ID} component={props => <MeterRoundContainer {...props} />} />
                            </ErrorBoundary>
                            <ErrorBoundary>
                                <Route exact path={paths.TAKE_READING} component={props => <MeterReadingContainer {...props} />} />
                            </ErrorBoundary>
                        </div>
                    </div>
                    
                    <Footer /></div>
                </AuthenticatedComponent>
            </Switch>
        </div>;
    }
}
