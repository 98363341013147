import React, { Component } from 'react';
import css from 'CSS/components/ui/splashImage.css';

export default class SplashLogo extends Component {
    render() {
        return (
            <div className="row justify-content-center">
                <div className={css.splashImage}/>
            </div>
        );
    }
}