import React from 'react';
import { slide as MenuComponent } from 'react-burger-menu';
import Logout from 'JS/components/ui/logoutMenuItem';
import Debug from 'JS/components/ui/debugMenuItem';

const Menu = () => {
    return (
        <MenuComponent right >
            <Logout/>
            <Debug/>
        </MenuComponent>
    );
};

export default Menu;