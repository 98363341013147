export class UnauthorisedError extends Error {
    
    constructor(message, response, ...args) {
        super(message, ...args);
        
        this.response = response;
    }
}

export class NetworkError extends Error {
    constructor(response, status, message, ...args) {
        super(message, ...args);

        this.name = 'NetworkError';
        this.status = status;
        this.response = response;
    }
}

export class ReadingNotProcessableError extends Error {
    
    constructor(message, response, ...args) {
        super(message, ...args);
        
        this.response = response;
    }
}

export class DuplicateReadingError extends Error {
    
    constructor(message, response, ...args) {
        super(message, ...args);
        
        this.response = response;
    }
}