// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".meterListHeader__meterRoundName___DHo_c {\n    font-size: 16px; \n    margin-bottom: 5px;\n}\n\n.meterListHeader__meterListHeader___cjGOS {   \n    font-weight: 500;\n    padding-bottom: 5px;\n    padding: 10px 10px 0 10px;\n}\n\n.meterListHeader__itemBorderBottom___HrSrw {\n    width: 100%;\n    border-bottom: 1px solid #ccc;  \n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/meter/meterListHeader.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,6BAA6B;AACjC","sourcesContent":[".meterRoundName {\n    font-size: 16px; \n    margin-bottom: 5px;\n}\n\n.meterListHeader {   \n    font-weight: 500;\n    padding-bottom: 5px;\n    padding: 10px 10px 0 10px;\n}\n\n.itemBorderBottom {\n    width: 100%;\n    border-bottom: 1px solid #ccc;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterRoundName": "meterListHeader__meterRoundName___DHo_c",
	"meterListHeader": "meterListHeader__meterListHeader___cjGOS",
	"itemBorderBottom": "meterListHeader__itemBorderBottom___HrSrw"
};
export default ___CSS_LOADER_EXPORT___;
