import { get } from './baseApi';

class SiteApi {
    static getSites(token, contractId) {
        return get(token, '/site/filtered?contract_id='+contractId+'&query=has:rounds');
    }
    
    static getSite(token, siteId) {
        return get(token, '/site/'+siteId);
    }
}

export default SiteApi;