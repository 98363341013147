import React from 'react';
import css from 'CSS/components/ui/header.css';
import * as appText from 'JS/language/en/appText';

const Header = () => {
    return (
        <header className="engiePrimaryBackgroundColour">
            <div className={css.headerText}>{appText.C3NTINEL_LOGO_TEXT}<sup>{appText.C3NTINEL_LOGO_SUP_TEXT}</sup>
            </div>
        </header>
    );
};

export default Header;