import React, { Component } from 'react';
import PropTypes from 'prop-types';
import css from 'CSS/components/ui/collapsablePanel.css';
import AnimateHeight from 'react-animate-height';

export default class CollapsablePanel extends Component {
                
    constructor(props) {
        
        super(props);
      
        this.state = {
            height: 0,
            icon: "keyboard_arrow_down"
        };
        
        this.togglePanel = this.togglePanel.bind(this);
    }
    
    togglePanel() {
        const { height } = this.state;

        this.setState({
            height: height === 0 ? "auto" : 0,
            icon: height === 0 ? "keyboard_arrow_up" : "keyboard_arrow_down"
        });
    }
            
    render() {
      
        return (
                     
            <div className={css.panelContainer}>
                <div className={css.headerButton} onClick={this.togglePanel}>
                    <span className={css.panelName}>{this.props.title}</span>
                    <span className={css.iconArrow + " engiePrimaryColour"}><i className="engie-icons">{this.state.icon}</i></span>
                </div>
             
                <AnimateHeight duration={500} height={this.state.height}>
                    <div className={css.panelContent} >
                        {this.props.children}
                    </div>
                </AnimateHeight>
            </div>
        );
    }
}

CollapsablePanel.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

