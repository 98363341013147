
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/dialog/dialogFooter.css';

function OkCancelDialogFooter(props) {
    return (
       
       <div className="modal-footer">
            <button type="button"
                    onClick={props.onOkClick}
                    className="engie-btn btn-primary btn-rounded engiePrimaryBackgroundColour engiePrimaryHoverBgColour"
                    data-dismiss="modal">
                    
                <i className={css.footer + " engie-icons"}>done</i>
            </button>&nbsp;&nbsp;
            <button type="button"
                    className="engie-btn btn-secondary btn-rounded engieSecondaryBackgroundColour engieSecondaryHoverBgColour"
                    data-dismiss="modal">
                    
                <i className={css.footer + " engie-icons"}>clear</i>
            </button>
        </div>
    );
}

OkCancelDialogFooter.propTypes = {
    onOkClick: PropTypes.func.isRequired
};

export default OkCancelDialogFooter;