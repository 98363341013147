import * as types from './actionTypes';
import { login, logout } from 'redux-implicit-oauth2';
import { logout as apiLogout } from 'JS/service/api/baseApi';
import {config} from 'JS/service/api/authentication';

export function requestLogin() {
    return function(dispatch, getState) {
        dispatch(login(config));
    };
}

export function requestLogout() {
    return function(dispatch, getState) {
        const token = getState().auth.token;

        return apiLogout(token)
            .finally(() => {
                dispatch(logout());
            });
    };
}