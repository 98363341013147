import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/dialog/messageDialogContent.css';

import warning from '../../../assets/images/container/warning.jpg';
import * as appText from 'JS/language/en/appText';

class MessageDialogContent extends Component {
    
    constructor(props, context) {
        super(props, context);
    }
       
    render() {
                 
        return (
            <div>
                <img className={css.warning} src={warning} />
                <div className="modal-body">
                    <div>{this.props.dialogMessage}</div>
                </div>
            </div>
        );
    }
}

MessageDialogContent.propTypes = {
    dialogMessage: PropTypes.string.isRequired
};

export default MessageDialogContent;



  