// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.previousReading__meterReadingPrevious___KWPJl {\n    font-size: 16px;\n    font-weight: normal;\n    margin-top: 5px;  \n}\n\n.previousReading__meterReadingPrevious___KWPJl span {\n    font-weight: bold;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html.phone .previousReading__meterReadingPrevious___KWPJl {\n        font-size: 12px;\n        margin-top: 0;\n        margin-bottom: -6px;\n    }\n}", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/reading/previousReading.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;;IAEI;QACI,eAAe;QACf,aAAa;QACb,mBAAmB;IACvB;AACJ","sourcesContent":["\n.meterReadingPrevious {\n    font-size: 16px;\n    font-weight: normal;\n    margin-top: 5px;  \n}\n\n.meterReadingPrevious span {\n    font-weight: bold;\n}\n\n@media screen and (orientation: landscape) {\n       \n    html:global(.phone) .meterReadingPrevious {\n        font-size: 12px;\n        margin-top: 0;\n        margin-bottom: -6px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"meterReadingPrevious": "previousReading__meterReadingPrevious___KWPJl"
};
export default ___CSS_LOADER_EXPORT___;
