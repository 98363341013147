import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class RadioInput extends Component {
           
    render() {
              
        return (
            <span>
                <input onChange={this.props.updateOptionState}
                       checked={this.props.checked}
                       name={this.props.name}
                       type="radio"
                       value={this.props.value}
                       id={this.props.id} />
                <label htmlFor={this.props.id} className={this.props.className} >{this.props.label}</label>
            </span>
        );
    }
}

RadioInput.propTypes = {
    updateOptionState: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

