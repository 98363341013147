// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.messageDialogContent__warning___WpgjZ {\n    margin: 0 auto;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/dialog/messageDialogContent.css"],"names":[],"mappings":";AACA;IACI,cAAc;AAClB","sourcesContent":["\n.warning {\n    margin: 0 auto;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warning": "messageDialogContent__warning___WpgjZ"
};
export default ___CSS_LOADER_EXPORT___;
