// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.backLink__backLinkContainer___YTiLf {\n    position: relative;\n    font-size: 12px;\n    cursor: pointer;\n    height: 23px;   \n}\n\n.backLink__backLinkIcon___DV477 {\n    display: inline-block;\n    margin-left: -4px;\n}\n\n.backLink__backLink___IhDIb {\n    display: inline-block;\n    vertical-align: top;\n    text-transform: uppercase;\n}\n\n.backLink__backLink___IhDIb:hover {\n    text-decoration: none;\n}\n\n.backLink__backLinkIcon___DV477 i {\n    font-size: 18px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/backLink.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["\n.backLinkContainer {\n    position: relative;\n    font-size: 12px;\n    cursor: pointer;\n    height: 23px;   \n}\n\n.backLinkIcon {\n    display: inline-block;\n    margin-left: -4px;\n}\n\n.backLink {\n    display: inline-block;\n    vertical-align: top;\n    text-transform: uppercase;\n}\n\n.backLink:hover {\n    text-decoration: none;\n}\n\n.backLinkIcon i {\n    font-size: 18px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backLinkContainer": "backLink__backLinkContainer___YTiLf",
	"backLinkIcon": "backLink__backLinkIcon___DV477",
	"backLink": "backLink__backLink___IhDIb"
};
export default ___CSS_LOADER_EXPORT___;
