import React from 'react';
import PropTypes from 'prop-types';

import * as appText from 'JS/language/en/appText';

import css from 'CSS/components/meterRound/meterRoundHeader.css';

function MeterRoundHeader(props) {
    return (
        <div className={css.meterRoundHeader}>
            <div className={css.titleContainer}>
                <div className="contractHeading">{props.contractName ? props.contractName : appText.LOADING_TEXT}</div>
                <div className="siteName">{props.siteName ? props.siteName : appText.LOADING_TEXT}</div>
            </div>
        </div>
    );
}

MeterRoundHeader.propTypes = {
    siteName: PropTypes.string.isRequired,
    contractName: PropTypes.string.isRequired
};

export default MeterRoundHeader;
