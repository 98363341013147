import {combineReducers} from 'redux';
import contracts from './contractReducer';
import sites from './siteReducer';
import meterRounds from './meterRoundReducer';
import meterRoundDetails from './meterRoundDetailsReducer';
import meterRoundDownloadStatus from './meterRoundDownloadStatusReducer';
import meterRoundProgress from './meterRoundProgressReducer';
import ajaxCallsInProgress from './ajaxStatusReducer';
import debug from './debugReducer';
import { authReducer as auth } from 'redux-implicit-oauth2';

const rootReducer = combineReducers({
   contracts,
   sites,
   meterRounds,
   meterRoundDetails,
   meterRoundDownloadStatus,
   meterRoundProgress,
   ajaxCallsInProgress,
   auth,
   debug
});

export default rootReducer;