// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.notificationMessage__notificationText___Xa2RN {\n    font-size: 14px;\n    margin: 5px 0 5px 0;\n    padding: 3px;\n}\n\n.notificationMessage__notificationIcon___rezxS {\n    top: 4px;\n    position: relative;\n    font-size: 18px;\n}\n\n\n", "",{"version":3,"sources":["webpack://./src/main/assets/css/components/ui/notificationMessage.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,QAAQ;IACR,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":["\n.notificationText {\n    font-size: 14px;\n    margin: 5px 0 5px 0;\n    padding: 3px;\n}\n\n.notificationIcon {\n    top: 4px;\n    position: relative;\n    font-size: 18px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notificationText": "notificationMessage__notificationText___Xa2RN",
	"notificationIcon": "notificationMessage__notificationIcon___rezxS"
};
export default ___CSS_LOADER_EXPORT___;
