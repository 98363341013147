import React, { Component } from 'react';
import PropTypes from 'prop-types';

import css from 'CSS/components/ui/panelContainer.css';

function PanelContainer(props) {
    return (
        <div className={css.panelShadow}>
            {props.children}
        </div>
    );
}

PanelContainer.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

export default PanelContainer;